import api, { initialize_api } from "@/utils/api";
import FormatSemanaFriedman from "@/utils/helpers/FormatSemanaFriedman";
import ListadoRutasXPermiso from "@/utils/helpers/ListadoRutasXPermiso";
import {
  CurrencyToDecimal,
  DecimalToCurrency,
} from "@/utils/helpers/CurrencyPrettier";

export default {
  sendMailNuevoTraslado({ state }, {
    correo_destino,
    codigo_unidad_origen,
    codigo_empleado,
    pais,
    codigo_unidad_destino,
  }) {
    try {
      const to = correo_destino;
      const subject = "Traslados Pendientes de Autorizar";
      const body =
        " <html> " +
        "<head> " +
        " <style> " +
        "  body {  " +
        "    font-family: Arial, sans-serif;  " +
        "    text-align: center; " +
        "    margin: 20px; " +
        "  } " +
        "  h1 { " +
        "    color: #333; " +
        "  } " +
        " </style> " +
        "</head> " +
        "<body> " +
        "<h1>POR FAVOR INGRESE A OCTHOPUS A AUTORIZAR LOS TRASLADOS PENDIENTES</h1> " +
        "<p> " +
        "  Codigo Unidad Origen: " + codigo_unidad_origen + ",  " +
        "  Codigo Empleado: " + codigo_empleado + ", " +
        "  Codigo Unidad Destino: " + codigo_unidad_destino + " " +
        "</p> " +
        "</body> " +
        "</html>";

      const mail = {
        mailTo: to.toString(),
        mailSubject: subject.toString(),
        mailBody: body.toString(),
      };

      api.sendMail(mail);
    } catch (ex) { }
  },
  async sendMailAceptacion(
    { state },
    {
      codigo_empleado,
      nombre_empleado,
      puesto,
      plaza_nueva,
      unidad_origen,
      unidad_destino,
    }
  ) {
    try {
      const to = state.UserInfo.username;
      const subject = `Traslado Autorizado - Empleado #${codigo_empleado}`;
      const body = `<html><p>Queremos informarte que se <span style="background-color: yellow">Autorizo el traslado</span> de nuestro compañero: <span style="color: red; font-size: 14px;">${codigo_empleado} - ${nombre_empleado} - Puesto: ${puesto}</span></p></html>`;

      const mail = {
        mailTo: to.toString(),
        mailSubject: subject.toString(),
        mailBody: body.toString(),
      };

      api.sendMail(mail);
    } catch (ex) { }
  },
  sendMailRechazo(
    { state },
    {
      codigo_empleado,
      nombre_empleado,
      puesto,
      plaza_nueva,
      unidad_origen,
      unidad_destino,
    }
  ) {
    try {
      const to = state.UserInfo.username;
      const subject = `Traslado Rechazado - Empleado #${codigo_empleado}`;
      const body = `<html><p>Queremos informarte que se <span style="background-color: yellow">Rechazo el traslado</span> de nuestro compañero: <span style="color: red; font-size: 14px;">${codigo_empleado} - ${nombre_empleado} - Puesto: ${puesto}</span></p></html>`;

      const mail = {
        mailTo: to.toString(),
        mailSubject: subject.toString(),
        mailBody: body.toString(),
      };

      api.sendMail(mail);
    } catch (ex) { }
  },
  /* LISTADO PLAZAS DISPONIBLES = Math(Plazas Totales - Plazas Activas)*/
  async llenarPlazasDisponibles({ commit }) {
    try {
      const plazas_activas = await api.plazas_metas();
      const plazas_totales = await api.plazas_list();

      if (Array.isArray(plazas_activas) && plazas_activas.length > 0) {
        const plazas_disponibles = [];

        plazas_totales.forEach((plaza) => {
          const exist = plazas_activas.some(
            (p) => p?.NombrePlaza === plaza?.NombrePlaza
          );

          if (!exist) {
            plazas_disponibles.push(plaza);
          }
        });

        commit("setListadoPlazas", { plazas_disponibles, plazas_activas });
      } else {
        commit("setListadoPlazas", {
          plazas_disponibles: plazas_totales,
          plazas_activas,
        });
      }
    } catch (ex) {
      const message = {
        visible: true,
        data: {
          type: "error",
          title: "Ha ocurrido un error.",
          subtitle: "No se pudo obtener el listado de plazas disponibles.",
          descripcion: ex,
        },
      };

      commit("showAlert", message);
    }
  },
  /* PROCESO SAP MICROS EN SEGUNDO PLANO */
  async actualizarVentasSegundoPlano({ commit }, params) {
    try {
      const response = await api.ventas_sap_micros(params?.pais, params?.mail);

      if (response.status === 200) {
        const message = {
          visible: true,
          data: {
            type: "info",
            title: "Proceso completado!",
            subtitle: "Detalle:",
            description: response?.data,
          },
        };

        commit("showAlert", message);
      } else {
        throw "El proceso en segundo plano no pudo ser completado.";
      }
    } catch (ex) {
      const message = {
        visible: true,
        data: {
          type: "error",
          title: "Error del servidor, (actualizar ventas sap micros)",
          description: ex,
        },
      };

      commit("showAlert", message);
    }
  },
  /* OBTIENE UNIDADES PARA METAS Y DISTRIBUCION = Unidades activas asignadas al usuario */
  async getUnidadesDistribucion({ commit, state }) {
    try {
      commit("showLoading", true);

      const body = await api.unidades_all();

      if (!body || !Array.isArray(body) || body.length <= 0) {
        throw body;
      }

      const paises = state.UserEssentials.assigned.paises;
      const unidades = state.UserEssentials.assigned.unidades;

      const unidades_ordenadas = body.map(b => ({ ...b, key: `${b.Pais + b.Codigo_Unidad}`, text: `${b.Codigo_Unidad} - ${b.Nombre_Unidad} - ${b.Pais}` }))

      const unidades_activas = unidades_ordenadas.filter(
        (u) => u.Inactive === "N" && u.Tipo_Unidad !== "M"
      );

      const unidades_asignadas = unidades.map((u) => {
        const unidad_completa = unidades_activas.find(
          (b) => b.key === (u.CodigoPais + u.CodigoUnidad)
        );

        return {
          NombrePais: paises.find((p) => p.CodigoPais === u.CodigoPais)?.Pais,
          ...unidad_completa,
        };
      });

      const unidades_asignadas_w_formato = unidades_asignadas.map((b) => {
        return {
          text: `${b?.Codigo_Unidad} - ${b?.Nombre_Unidad}`,
          value: {
            ...b,
          },
        };
      });

      commit("setCatalogoUnidades", unidades_activas);
      commit("setUnidadesDistribucion", unidades_asignadas);
      commit("setUnidadesDistribucionFormato", unidades_asignadas_w_formato);
    } catch (ex) {
      const message = {
        visible: true,
        data: {
          type: "error",
          title:
            "Error del servidor, (unidades distribucion for user not found)",
          description: ex,
        },
      };

      commit("showAlert", message);
    }

    commit("showLoading", false);
  },
  /* CALENDARIO FRIEDMAN COMPLETO CON FORMATO */
  async getCalendarioFriedman({ commit }) {
    try {
      commit("showLoading", true);
      commit("cleanCalendarioFriedman");

      const data_semana = await api.calendario_friedman();

      if (!data_semana) {
        throw data_semana;
      }

      const data_w_format = FormatSemanaFriedman(data_semana);

      commit("setCalendarioFriedman", data_w_format);
    } catch {
      const message = {
        visible: true,
        data: {
          type: "error",
          title: "Error del servidor, (calendario friedman for user not found)",
          description: ex,
        },
      };

      commit("showAlert", message);
    }

    commit("showLoading", false);
  },
  /* REALIZA EL FILTRO DE CALENDARIO FRIEDMAN POR ANIO */
  filterSemanasAnio({ commit, state }, anio) {
    const semanas_x_anio = state.ListadoSemanasFriedman[anio] || [];

    commit("setSemanasXAnio", semanas_x_anio);
  },
  /* REALIZA EL FILTRO DE CALENDARIO FRIEDMAN POR MES */
  filterSemanasMes({ commit, state }, mes) {
    const semanas_x_mes =
      state.semanas_x_anio.length > 0
        ? state.semanas_x_anio.filter((s) => s.value.No_Mes === mes)
        : [
          {
            text: "No disponible",
            value: { No_Semana: 0 },
          },
        ];

    commit("setSemanasXMes", semanas_x_mes);
  },
  /* REALIZA EL PROCESO DE LOGIN PARA LA API DE METAS */
  async loginApiMetas({ commit }) {
    try {
      const { data } = await api.login();

      if (data?.token) {
        commit("setMetasApiToken", data.token);

        initialize_api();
      } else {
        throw data;
      }
    } catch (ex) {
      const message = {
        visible: true,
        data: {
          type: "error",
          title: "Error del servidor, (metas/login not found)",
          description: ex,
        },
      };

      commit("showAlert", message);
    }
  },
  /* REGISTRA DE FORMA GLOBAL LOS PERMISOS DEL USUARIO Y LAS RUTAS CON ACCESO */
  async setPermisosXRol({ commit, state }) {
    try {
      const body = await api.permissions(state.UserInfo?.username);

      const result = body[0];

      if (result) {
        const information = {
          IdPlazaEvolution: result?.IdPlazaEvolution,
          IdUsuario: result?.IdUsuario,
          NombrePlaza: result?.NombrePlaza,
          CodigoEmpleadoEvolution: result?.CodigoEmpleadoEvolution,
        };

        const permissions = [];

        Object.keys(result).forEach((key) => {
          if (result[key] === 1) {
            permissions.push(key);
          }
        });

        const routes_with_access = [];
        const NombreRutas = Object.keys(ListadoRutasXPermiso);

        for (const p of permissions) {
          if (NombreRutas.includes(p))
            routes_with_access.push(ListadoRutasXPermiso[p]);
        }

        commit("setPermissions", {
          information,
          permissions,
          routes_with_access,
        });
      } else {
        throw body;
      }
    } catch (ex) {
      const message = {
        visible: true,
        data: {
          type: "error",
          title: "Error del servidor, (metas/permissions not found)",
          description: ex,
        },
      };

      commit("showAlert", message);
    }
  },
  /* OBTIENE LOS DATOS ESENCIALES PARA MANTENER LA SESION DEL USUARIO */
  async getUserEssentials({ commit, state }) {
    try {
      commit("showLoading", true);

      const username = state.UserInfo?.username;
      if (!username) throw "El usuario aun no ha sigo asignado";

      const petitions = [
        api.divisiones_asignadas(username),
        api.catalogo_paises(username),
        api.paises_asignados(username),
        api.unidades_asignadas(username),
      ];

      Promise.allSettled(petitions).then((responses) => {
        responses.forEach((response) => {
          if (response.status !== "fulfilled") {
            throw "Essentials data for user not found.";
          }
        });

        const divisiones = responses[0].value;
        const catPaises = responses[1].value;
        const paises = responses[2].value;
        const unidades = responses[3].value;

        commit("setUserEssentials", {
          catalogs: {
            paises: catPaises,
          },
          assigned: {
            divisiones,
            paises,
            unidades,
          },
        });
      });
    } catch (ex) {
      const message = {
        visible: true,
        data: {
          type: "error",
          title: "Error del servidor, (essentials data for user not found)",
          description: ex,
        },
      };

      commit("showAlert", message);
    }

    commit("showLoading", false);
  },
  /* OBTIENE LAS METAS X SEMANA X UNIDAD */
  async setMetasXSemana(
    { commit, state },
    { pais, fechaInicio, fechaFin, anio, nomes, nosemana }
  ) {
    try {
      commit("showLoading", true);

      const unidades_totales = await api.unidades(pais);
      const data_semana_meta = await api.metas(fechaInicio, fechaFin, pais);

      const unidades = unidades_totales.filter((u) => u.Inactive === "N" && u.Tipo_Unidad !== "M");
      const metas = [];
      const data_semana_meta_dic = {};
      let index = 0;

      const moneda = pais == "GT" ? "Q" : "₡";
      commit("setMoneda", moneda);

      //convert data semana meta para facil extraccion de data
      for (const data of data_semana_meta) {
        data_semana_meta_dic[data.codigo_unidad] = data;
      }

      /* const addRow = (unidad) => {
        const meta = data_semana_meta_dic[unidad.Codigo_Unidad].Meta_Unidad;
        const minimo = data_semana_meta_dic[unidad.Codigo_Unidad].Minimo_Unidad;
        metas.push({
          codigo_unidad: unidad.Codigo_Unidad,
          nombre_unidad: unidad.Nombre_Unidad,
          no_semana: nosemana,
          pais: unidad.Pais,
          no_mes: nomes,
          no_año: anio,
          fecha_inicio: fechaInicio,
          fecha_fin: fechaFin,
          meta: meta ? Number(meta).toFixed(2) : "",
          minimo: minimo ? Number(minimo).toFixed(2) : "",
        });
      }; */

      for (const unidad of unidades) {
        //Gastronomia solo debe de ver las unidades tipo tipo in ('C','M','R')
        if (
          state.UserEssentials.assigned.divisiones.includes("Gastronomía") &&
          ["C", "M", "R"].includes(unidad.tipo)
        ) {
          //addRow(unidad);

          let meta_row = {
            codigo_unidad: unidad.Codigo_Unidad,
            nombre_unidad: unidad.Nombre_Unidad,
            no_semana: nosemana,
            pais: pais,
            no_mes: nomes,
            no_año: anio,
            fecha_inicio: fechaInicio,
            fecha_fin: fechaFin,
            meta: "",
            minimo: "",
          };
          for (var i = 0; i < data_semana_meta.length; i++) {
            if (data_semana_meta[i].Codigo_Unidad == meta_row.codigo_unidad) {
              meta_row.meta = data_semana_meta[i].Meta_Unidad;
              meta_row.minimo = data_semana_meta[i].Minimo_Unidad;
              /*
              meta_row.meta = DecimalToCurrency(
                data_semana_meta[i].Meta_Unidad
              );
              meta_row.minimo = DecimalToCurrency(
                data_semana_meta[i].Minimo_Unidad
              );
              */
            }
          }
          metas[index] = meta_row;
          index += 1;
        } //Moda solo debe de ver las unidades tipo tipo in ('T')
        else if (
          state.UserEssentials.assigned.divisiones.includes("Moda") &&
          unidad.tipo === "T"
        ) {
          //addRow(unidad);

          let meta_row = {
            codigo_unidad: unidad.Codigo_Unidad,
            nombre_unidad: unidad.Nombre_Unidad,
            no_semana: nosemana,
            pais: unidad.Pais,
            no_mes: nomes,
            no_año: anio,
            fecha_inicio: fechaInicio,
            fecha_fin: fechaFin,
            meta: "",
            minimo: "",
          };
          for (var i = 0; i < data_semana_meta.length; i++) {
            if (data_semana_meta[i].Codigo_Unidad == meta_row.codigo_unidad) {
              meta_row.meta = data_semana_meta[i].Meta_Unidad;
              meta_row.minimo = data_semana_meta[i].Minimo_Unidad;
              /*
              meta_row.meta = DecimalToCurrency(
                data_semana_meta[i].Meta_Unidad
              );
              meta_row.minimo = DecimalToCurrency(
                data_semana_meta[i].Minimo_Unidad
              );
              */
            }
          }
          metas[index] = meta_row;
          index += 1;
        }
      }

      commit("setMetasTotales", metas);
    } catch (ex) {
      const message = {
        visible: true,
        data: {
          type: "error",
          title: "Error del servidor, (Set Metas X Semana not found)",
          description: ex,
        },
      };

      commit("showAlert", message);
    }

    commit("showLoading", false);
  },

  /* -------------------------------------- */

  /*
   ** MUTACIONES REGISTRADAS COMO ACCIONES SIN SENTIDO
   ** SE MANTIENEN COMO CODIGO LEGACY POR QUE NO SE PARA QUE SIRVEN
   */
  accionsumartotalesmetas({ commit }) {
    commit("sumartotalesmetas");
  },

  accionclearLlenarMeta({ commit }) {
    commit("clearLlenarMeta");
  },

  accioncleardistribucion({ commit }) {
    commit("cleardistribucion");
  },

  accionclearalertaNoMeta({ commit }) {
    commit("clearalertaNoMeta");
  },

  accionsetPencil({ commit, state }, stado) {
    commit("setPencil", stado);
  },
  accionsetverMetaMercado({ commit, state }, stado) {
    commit("setverMetaMercado", stado);
  },
  accionsetcodigoMercado({ commit, state }, stado) {
    commit("setcodigoMercado", stado);
  },
  accionsetAnioMercado({ commit, state }, stado) {
    commit("setAnioMercado", stado);
  },
  accionsetMesMercado({ commit, state }, stado) {
    commit("setMesMercado", stado);
  },
  accionsetFechaFinMercado({ commit, state }, stado) {
    commit("setFechaFinMercado", stado);
  },
  /*
   ** SE EJECUTA EN TABLA PUESTOS _TEMP_ Y CARD REASIGNAR MESEROS
   ** ?? POSIBLEMENTE SE PUEDA CAMBIAR POR LA ACCION QUE UTILIZA TABLA PLAZAS ??
   */
  async accionAutoLlenarCatalogoPaises({ commit, state }) {
    const Lista = await api.get_catalogos_paises();
    commit("autoLlenarCatalogosPaises", Lista);
  },
  /* ?? SE EJECUTA EN FORMULARIO DISTRIBUCION _TEMP_ ?? NO SE SI CONTINUA EN USO */
  async accionUnidadesDistribuccion({ commit, state }) {
    let unidades = state.UnidadesAll;
    let unidadesAsignadas = state.dataUnidadesasignadas;
    let dataPaisAsignadoIN = [];
    let index = 0;
    for (const unidad of unidades) {
      for (const unidadeAsignada of unidadesAsignadas) {
        if (
          unidad.Codigo_Unidad == unidadeAsignada.CodigoUnidad &&
          unidad.Pais == unidadeAsignada.CodigoPais
        ) {
          dataPaisAsignadoIN[index] = unidad;
          index += 1;
        }
      }
    }
    state.dataUnidadesasignadas = dataPaisAsignadoIN;
  },
  /*
   ** ?? SE EJECUTA EN TABLA PUESTOS _TEMP_ ??
   ** VALIDAR FUNCIONAMIENTO Y ELIMINAR
   */
  async accionAutoLlenarCatalogoDivisiones({ commit, state }) {
    const Lista = await api.get_catalogos_divisiones();

    commit("autoLlenarCatalogosDivisiones", Lista);
  },

  /* :NUEVO: -> OBTENER DISTRIBUCION DE METAS */
  async getDistribucionMetasUnidad(
    { commit, state },
    {
      codigo_unidad,
      fecha_inicio,
      fecha_fin,
      fecha_buscar,
      anio,
      no_mes,
      no_semana,
      pais,
    }
  ) {
    try {

      if (fecha_buscar !== null && fecha_buscar !== undefined) {
        const dtFechaBuscar = Date.parse(fecha_buscar);

        const semana_filtrada = state.ListadoSemanasFriedman.find((semana) => {
          const fecha_inicial = Date.parse(semana?.value?.Fecha_Inicial_YMD);
          const fecha_final = Date.parse(semana?.value?.Fecha_Final_YMD);

          return dtFechaBuscar >= fecha_inicial && dtFechaBuscar <= fecha_final;
        });

        if (semana_filtrada !== undefined) {
          fecha_inicio = semana_filtrada?.value?.Fecha_Inicial_YMD;
          fecha_fin = semana_filtrada?.value?.Fecha_Final_YMD;
        } else {
          throw "No fue posible encontrar una semana que coincida con la fecha a buscar dentro del calendario friedman.";
        }
      }

      const meta_total_unidad = await api.metas_unidad({
        fecha_inicio,
        fecha_fin,
        codigo_unidad,
        pais,
      });

      if (Array.isArray(meta_total_unidad) && meta_total_unidad.length > 0) {
        const moneda = pais == "GT" ? "Q" : "₡";
        commit("setMoneda", moneda);

        const monto_meta_unidad = (moneda == "GT") ?
          DecimalToCurrency(meta_total_unidad[0]?.Meta_Unidad) :
          moneda + ' ' + (meta_total_unidad[0]?.Meta_Unidad).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });

        const monto_minimo_unidad = (moneda == "GT") ?
          DecimalToCurrency(meta_total_unidad[0]?.Minimo_Unidad) :
          moneda + ' ' + (meta_total_unidad[0]?.Minimo_Unidad).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });

        commit("setMetaMinimodistribucion", {
          meta: monto_meta_unidad,
          minimo: monto_minimo_unidad,
        });

        const detalle_metas_historico = await api.detalle_metas({
          fecha_inicio,
          fecha_fin,
          codigo_unidad,
          pais,
        });

        const empleados_unidad_semana = [];
        const datos_detalle_semana = {
          sumameta: 0,
          sumaminimo: 0,
        };

        if (
          Array.isArray(detalle_metas_historico) &&
          detalle_metas_historico.length > 0
        ) {
          const detalle_empleados = await api.empleados_unidades();

          for (let index = 0; index < detalle_metas_historico.length; index++) {
            const empleado = detalle_metas_historico[index];
            const empleado_filtrado = detalle_empleados.find(
              (row) => row.exp_codigo_alternativo === empleado.Codigo_Empleado
            );
            let nombre_empleado =
              empleado_filtrado === undefined
                ? "EMPLEADO DE BAJA"
                : empleado_filtrado.exp_NOMBRES_APELLIDOS;

            const total_venta_empleado =
              detalle_metas_historico[index].Total_Venta_Empleado;
            const minimo_venta_empleado =
              detalle_metas_historico[index].Minimo_Venta_Empleado;

            datos_detalle_semana.sumameta += total_venta_empleado;
            datos_detalle_semana.sumaminimo += minimo_venta_empleado;

            if (empleado.Codigo_Empleado == "0")
            {
              nombre_empleado = "**CAJERO**";
            }

            const metas_empleado = {
              codigo_empleado: empleado.Codigo_Empleado,
              codigo_unidad: codigo_unidad,
              fecha_inicio: fecha_inicio,
              nombres_apellidos: nombre_empleado,
              no_semana: no_semana,
              no_horas: detalle_metas_historico[index].No_Horas || 0,
              es_nuevo: false,
              meta: DecimalToCurrency(total_venta_empleado),
              minimo: DecimalToCurrency(minimo_venta_empleado),
              Observaciones: detalle_metas_historico[index].Observaciones,
              pais: pais,
            };

            empleados_unidad_semana[index] = metas_empleado || {};
          }
        } else {

          let unidades = state.UnidadesAll.find(u => u.Codigo_Unidad === codigo_unidad && u.Pais === pais)
         
          if (unidades.Tipo_Unidad == "K") {
         
            const estructura_meta = {
              codigo_empleado: "0",
              codigo_unidad: codigo_unidad,
              fecha_inicio: fecha_inicio,
              nombres_apellidos: "**CAJERO**",
              no_semana: no_semana,
              no_horas: null,
              es_nuevo: true,
              meta: DecimalToCurrency(0),
              minimo: DecimalToCurrency(0),
              Observaciones: "",
              pais: pais,
            };

            empleados_unidad_semana[0] = estructura_meta || {};

          }
          else //Esto si es diferente a Kioskos para tiendas bistros etc..
          {
            const empleados_asignados = await api.empleados_unidad(
              codigo_unidad,
              pais
            );

            for (let index = 0; index < empleados_asignados.length; index++) {
              const estructura_meta = {
                codigo_empleado: empleados_asignados[index].Codigo_Empleado,
                codigo_unidad: codigo_unidad,
                fecha_inicio: fecha_inicio,
                nombres_apellidos: empleados_asignados[index].Nombres_Apellidos,
                no_semana: no_semana,
                no_horas: null,
                es_nuevo: true,
                meta: DecimalToCurrency(0),
                minimo: DecimalToCurrency(0),
                Observaciones: "",
                pais: pais,
              };

              empleados_unidad_semana[index] = estructura_meta || {};
            }
          }

        }

        const metadistribucion = DecimalToCurrency(
          datos_detalle_semana.sumameta
        );
        const minimodistribucion = DecimalToCurrency(
          datos_detalle_semana.sumaminimo
        );

        commit("sumartotalesdistribucion", {
          metadistribucion,
          minimodistribucion,
        });
        commit("autoLlenarDistribucion", empleados_unidad_semana);

        //--consulto si la unidad esta dentro de proceso mercado
        commit("setverMetaMercado", false);
        commit("setcodigoMercado", "");
        commit("setAnioMercado", 0);
        commit("setMesMercado", 0);
        commit("setFechaFinMercado", 0);
        const detalle_metas_mercado = await api.get_catalogo_mercados({ codigo_unidad });
        let visualizarIngresoMetaMercado = false;
        if (detalle_metas_mercado.length > 0) {
          const asesores_unidad = await api.empleados_unidades(codigo_unidad);
          //--Busco si solo si esta un Asesor de ventas Mercado en campo plz_nombre
          //Ejemplo
          let fraseBuscada = "MERCADO";
          let fraseEncontrada = false;
          for (let index = 0; index < asesores_unidad.length; index++) {
            let pue_nombre = (asesores_unidad[index].pue_nombre).toUpperCase();
            if (pue_nombre != null && pue_nombre.includes(fraseBuscada)) {
              fraseEncontrada = true;
            }
          }
          if (fraseEncontrada == false) {
            visualizarIngresoMetaMercado = true;
          }
          else {
            visualizarIngresoMetaMercado = false;
          }
          commit("setverMetaMercado", visualizarIngresoMetaMercado);
          commit("setcodigoMercado", detalle_metas_mercado[0].codigo_mercadito);
          commit("setAnioMercado", anio);
          commit("setMesMercado", no_mes);
          commit("setFechaFinMercado", fecha_fin);
        }

      } else {
        state.alertaNoMeta = true;
      }



    } catch (ex) {
      const message = {
        visible: true,
        data: {
          type: "error",
          title: "Error del servidor, (getDistribucionMetasUnidad not found)",
          description: ex,
        },
      };

      commit("showAlert", message);
    }
  },
  /* :NUEVO: -> PROCESO DISTRIBUCION METAS || PRORRATEO DE METAS */
  async calcularDistribuirMetasUnidad(
    { commit },
    { distribucion, monto_meta }
  ) {
    try {
      const nueva_distribucion = [];
      let sumameta = 0;
      let sumaminimo = 0;
      monto_meta = CurrencyToDecimal(monto_meta);

      if (
        Array.isArray(distribucion) &&
        distribucion.length > 0 &&
        monto_meta > 0
      ) {
        const total_horas = distribucion.reduce(
          (accumulator, currentValue) => accumulator + currentValue?.no_horas,
          0
        );

        if (total_horas > 0) {
          const valor_hora = monto_meta / total_horas;

          for await (const empleado of distribucion) {
            if (empleado?.no_horas > 0) {
              const nueva_meta = valor_hora * empleado?.no_horas;

              if (nueva_meta !== null && !isNaN(nueva_meta)) {
                const nuevo_minimo = (nueva_meta * 85) / 100;

                sumameta = sumameta + nueva_meta;
                sumaminimo = sumaminimo + nuevo_minimo;

                empleado.meta = DecimalToCurrency(nueva_meta);
                empleado.minimo = DecimalToCurrency(nuevo_minimo);

                nueva_distribucion.push(empleado);
              } else {
                throw `La nueva meta calculada para al empleado ${empleado.codigo_empleado} no es valida.`;
              }
            } else {
              nueva_distribucion.push(empleado);
            }
          }
        }

        const metadistribucion = DecimalToCurrency(sumameta);
        const minimodistribucion = DecimalToCurrency(sumaminimo);

        commit("sumartotalesdistribucion", {
          metadistribucion,
          minimodistribucion,
        });

        commit("autoLlenarDistribucion", nueva_distribucion);
      } else {
        throw `El objeto distribucion o meta, a procesar no es valido. 
          Object: ${JSON.stringify(distribucion)};
          Meta: ${monto_meta.toString()}`;
      }
    } catch (ex) {
      const message = {
        visible: true,
        data: {
          type: "error",
          title:
            "Error del servidor, (calcularDistribuirMetasUnidad not found)",
          description: ex,
        },
      };

      commit("showAlert", message);
    }
  },
  /* :NUEVO: -> PROCESO ACEPTACION DISTRIBUCION */
  async guardarDistribucionMetasUnidad({ commit, state }, { distribucion }) {
    try {
      const usuario = state.UserInfo.username.split("@")[0];
      for await (const empleado of distribucion) {
        const total_venta_empleado = CurrencyToDecimal(empleado.meta);

        if (empleado?.es_nuevo) {
          const horas =
            empleado.no_horas === undefined || empleado.no_horas === null
              ? 0
              : empleado.no_horas;
          const metas = {
            distribucionMetas: [
              {
                codigo_unidad: empleado.codigo_unidad,
                codigo_empleado: empleado.codigo_empleado,
                fecha_inicio: empleado.fecha_inicio,
                total_venta_empleado,
                usuario,
                pais: empleado.pais,
                no_horas: horas,
                observaciones: empleado.observaciones,
              },
            ],
          };

          await api.ingresar_distribucion_metas(metas);
        } else {
          const horas =
            empleado.no_horas === undefined || empleado.no_horas === null
              ? 0
              : empleado.no_horas;
          const metas = {
            fecha_inicio: empleado.fecha_inicio,
            codigo_unidad: empleado.codigo_unidad,
            pais: empleado.pais,
            codigo_empleado: empleado.codigo_empleado,
            body: {
              total_venta_empleado,
              no_horas: horas,
              usuario,
              observaciones: empleado.observaciones,
            },
          };

          await api.actualizar_distribucion_metas(metas);
        }
      }

      const message = {
        visible: true,
        data: {
          type: "info",
          title: "Metas registradas con éxito!",
          description: "Las metas fueron registradas correctamente.",
        },
      };

      commit("showAlert", message);
    } catch (ex) {
      const message = {
        visible: true,
        data: {
          type: "error",
          title:
            "Error del servidor, (guardarDistribucionMetasUnidad not found)",
          description: ex,
        },
      };

      commit("showAlert", message);
    }
  },

  /* SE EJECUTA AL INGRESAR UNA NUEVA META PARA LA UNIDAD -> PRORRATEA LAS METAS DE LA UNIDAD */
  async accionactualizardistribuccion(
    { commit, state },
    { montometa, pais, fecha_inicio, fecha_fin, Codigo_Unidad }
  ) {
    try {
      let total_horas = 0;
      const metaIngresada = montometa;
      let dataDistribuccion = [];

      const rowsEmpleados = await api.empleados_unidad(Codigo_Unidad, pais);
      const distribuciondetalle = await api.detalle_metas({
        fecha_inicio,
        fecha_fin,
        codigo_unidad: Codigo_Unidad,
        pais,
      });

      for (let i = 0; i < rowsEmpleados.length; i++) {
        const distribucion_empleado = distribuciondetalle.find(
          (d) => d.Codigo_Empleado === rowsEmpleados[i].Codigo_Empleado
        );

        if (distribucion_empleado) {
          dataDistribuccion.push(distribucion_empleado);
        }
      }

      if (typeof dataDistribuccion === "string") {
        throw (
          "Ocurrió un error al requerir las metas de la unidad, " +
          dataDistribuccion
        );
      }

      for (let i = 0; i < dataDistribuccion.length; i++) {
        if (dataDistribuccion[i].No_Horas != "") {
          total_horas += parseInt(dataDistribuccion[i].No_Horas);
        }
      }

      if (total_horas > 0) {
        let valorhora = metaIngresada / total_horas;

        for (let index = 0; index < dataDistribuccion.length; index++) {
          if (dataDistribuccion[index].No_Horas != "") {
            let nuevameta = parseFloat(
              valorhora *
              parseFloat(dataDistribuccion[index].No_Horas).toFixed(2)
            ).toFixed(2);
            if (nuevameta !== null && !isNaN(nuevameta)) {
              let nuevominimo = parseFloat((nuevameta * 85) / 100).toFixed(2);

              dataDistribuccion[index].Total_Venta_Empleado =
                DecimalToCurrency(nuevameta);
              dataDistribuccion[index].Minimo_Venta_Empleado =
                DecimalToCurrency(nuevominimo);

              let body = {
                total_venta_empleado: nuevameta,
                usuario: state.UserInfo?.username.split("@")[0],
                no_horas: dataDistribuccion[index].No_Horas,
                observaciones: dataDistribuccion[index].Observaciones,
              };

              let fechainicio = dataDistribuccion[index].Fecha_Inicio.replace(
                " 00:00:00",
                ""
              );

              let nueva_distribucion = await api.actualizar_distribucion_metas({
                fecha_inicio: fechainicio,
                codigo_unidad: dataDistribuccion[index].Codigo_Unidad,
                pais: pais,
                codigo_empleado: dataDistribuccion[index].Codigo_Empleado,
                body,
              });

              if (typeof nueva_distribucion === "string") {
                throw `Ocurrió un error al actualizar las metas del empleado: ${dataDistribuccion[index].Codigo_Empleado}, Error: ${nueva_distribucion}`;
              }
            } else {
              const message = {
                visible: true,
                data: {
                  type: "success",
                  title: "Meta guardada correctamente!",
                  subtitle:
                    "No se realizó el proceso de distribución automático.",
                  description: "La meta de la unidad es menor o igual a 0.",
                },
              };

              commit("showAlert", message);
            }
          }
        }
      }
    } catch (ex) {
      const message = {
        visible: true,
        data: {
          type: "error",
          title: "Ha ocurrido un error!",
          subtitle: "No se pudo completar el proceso de prorrateo.",
          description: ex,
        },
      };

      commit("showAlert", message);
    }
  },
  /* SE EJECUTA AL DISTRIBUIR METAS -> PRORRATEA LAS METAS DE LA UNIDAD */
  async accionactualizarmetaminimodistribuccion(
    { commit, state, dispatch },
    { distribuccion, montometa, pais, fecha_inicio, fecha_fin, Codigo_Unidad }
  ) {
    let total_horas = 0;
    const metaIngresada = montometa;
    let dataDistribuccion = [];

    if (distribuccion.length > 0) {
      dataDistribuccion = distribuccion;
    }

    for (let index = 0; index < dataDistribuccion.length; index++) {
      if (dataDistribuccion[index].no_horas != "") {
        total_horas += parseInt(dataDistribuccion[index].no_horas);
      }
    }

    if (total_horas > 0) {
      let valorhora = metaIngresada / total_horas;

      for (let index = 0; index < dataDistribuccion.length; index++) {
        if (dataDistribuccion[index].no_horas != "") {
          let nuevameta = parseFloat(
            valorhora * parseFloat(dataDistribuccion[index].no_horas).toFixed(2)
          ).toFixed(2);
          if (nuevameta !== null && !isNaN(nuevameta)) {
            let nuevaMinimo = parseFloat((nuevameta * 85) / 100).toFixed(2);
            dataDistribuccion[index].meta = DecimalToCurrency(nuevameta);
            dataDistribuccion[index].minimo = DecimalToCurrency(nuevaMinimo);

            let body = {
              total_venta_empleado: nuevameta,
              usuario: state.UserInfo?.username.split("@")[0],
              no_horas: dataDistribuccion[index].no_horas,
              observaciones: dataDistribuccion[index].observaciones,
            };

            await api.actualizar_distribucion_metas({
              fecha_inicio: dataDistribuccion[index].fecha_inicio,
              codigo_unidad: dataDistribuccion[index].codigo_unidad,
              pais: pais,
              codigo_empleado: dataDistribuccion[index].codigo_empleado,
              body,
            });
          }
        }
      }

      state.sumametadistribucion = 0;
      state.sumaminimodistribucion = 0;
      for (let index = 0; index < dataDistribuccion.length; index++) {
        let valor = parseFloat(
          dataDistribuccion[index].meta
            .replace(",", "")
            .replace(",", "")
            .replace(",", "")
            .replace(state.Moneda, "")
        );

        state.sumametadistribucion += valor;
        state.sumaminimodistribucion += parseFloat(
          dataDistribuccion[index].minimo
            .replace(",", "")
            .replace(",", "")
            .replace(",", "")
            .replace(state.Moneda, "")
        );
      }

      state.sumametadistribucion = DecimalToCurrency(
        state.sumametadistribucion
      );
      state.sumaminimodistribucion = DecimalToCurrency(
        state.sumaminimodistribucion
      );

      commit("autoLlenarDistribucion", dataDistribuccion);
    }
  },
  /* SE EJECUTA AL SOLICITAR METAS EN DISTRIBUCION */
  async accionautoLlenarDistribucion(
    { commit, state },
    { Codigo_Unidad, fecha_inicio, fecha_fin, anio, noMes, noSemana, Pais }
  ) {
    let datos_metas_unidad_in = await api.metas_unidad({
      fecha_inicio,
      fecha_fin,
      pais: Pais,
      codigo_unidad: Codigo_Unidad,
    });

    if (datos_metas_unidad_in.length > 0) {
      const rowsEmpleados = await api.empleados_unidad(Codigo_Unidad, Pais);
      const distribuciondetalle = await api.detalle_metas({
        fecha_inicio,
        fecha_fin,
        codigo_unidad: Codigo_Unidad,
        pais: Pais,
      });

      let moneda = Pais == "GT" ? "Q" : "₡";

      commit("setMoneda", moneda);

      const montometa = DecimalToCurrency(datos_metas_unidad_in[0].Meta_Unidad);
      const montominimo = DecimalToCurrency(
        datos_metas_unidad_in[0].Minimo_Unidad
      );

      commit("setMetaMinimodistribucion", {
        meta: montometa,
        minimo: montominimo,
      });

      let sumametadistribucion = 0;
      let sumaminimodistribucion = 0;
      const dataDistribuccion_edit = [];
      let indice = 0;

      for (let index = 0; index < rowsEmpleados.length; index++) {
        let No_Horas = "";
        let Total_Venta_Empleado = "0.00";
        let Minimo_Venta_Empleado = "0.00";
        let ObservacionesIN = "";

        for (let indexIn = 0; indexIn < distribuciondetalle.length; indexIn++) {
          if (
            distribuciondetalle[indexIn].Codigo_Empleado ==
            rowsEmpleados[index].Codigo_Empleado
          ) {
            No_Horas = distribuciondetalle[indexIn].No_Horas;
            Total_Venta_Empleado =
              distribuciondetalle[indexIn].Total_Venta_Empleado;
            Minimo_Venta_Empleado =
              distribuciondetalle[indexIn].Minimo_Venta_Empleado;
            ObservacionesIN = distribuciondetalle[indexIn].Observaciones;
            sumametadistribucion += Total_Venta_Empleado;
            sumaminimodistribucion += Minimo_Venta_Empleado;
          }
        }

        let registro_empleado = {
          codigo_empleado: rowsEmpleados[index].Codigo_Empleado,
          codigo_unidad: Codigo_Unidad,
          fecha_inicio: fecha_inicio,
          nombres_apellidos: rowsEmpleados[index].Nombres_Apellidos,
          no_semana: noSemana,
          no_horas: No_Horas || 0,
          meta: DecimalToCurrency(Total_Venta_Empleado),
          minimo: DecimalToCurrency(Minimo_Venta_Empleado),
          Observaciones: ObservacionesIN,
          pais: Pais,
        };

        dataDistribuccion_edit[indice] = registro_empleado;
        indice += 1;
      }

      sumametadistribucion = DecimalToCurrency(sumametadistribucion);
      sumaminimodistribucion = DecimalToCurrency(sumaminimodistribucion);
      commit("autoLlenarDistribucion", dataDistribuccion_edit);
      commit("sumartotalesdistribucion", {
        metadistribucion: sumametadistribucion,
        minimodistribucion: sumaminimodistribucion,
      });
    } else {
      state.alertaNoMeta = true;
    }
  },

  /*  */
  async accionAutoLlenarPlazasLista({ commit, state }) {
    const Lista = await api.plazas_list();
    commit("autoLlenarplazaLista", Lista);
  },
  async accionLlenarUnidadesAll({ commit, state }) {
    const Lista = await api.unidades_all();
    commit("autoLlenarUnidadesAll", Lista);
  },
  async accionAutoLlenarPlazas({ commit, state }) {
    if (
      state.UserPermissions.permissions.includes("PuedeAsignarRolesPermisos")
    ) {
      const Lista = await api.plazas_metas();
      commit("autoLlenarPuestos", Lista);
    }
  },
  async accionLlenarUsuarios({ commit, state }) {
    const usuarios_totales = await api.usuarios_AD();
    const usuarios_activos = await api.usuarios_list();

    if (Array.isArray(usuarios_activos) && usuarios_activos.length > 0) {
      const usuarios_disponibles = [];

      usuarios_totales.forEach((usuario) => {
        const exist = usuarios_activos.some(
          (u) => u?.Usuario === usuario?.userPrincipalName
        );

        if (!exist) {
          usuarios_disponibles.push(usuario);
        }
      });

      commit("autoLlenarUsuarios", usuarios_activos);
      commit("autoLlenarUsuariosAD", usuarios_disponibles);
    } else {
      commit("autoLlenarUsuarios", usuarios_activos);
      commit("autoLlenarUsuariosAD", usuarios_totales);
    }
  },
  async accionAutoLlenarMeta(
    { commit, state },
    { pais, fechaInicio, fechaFin, anio, noMes, noSemana }
  ) {
    const unidades = await api.unidades(pais);

    const dataSemanaMeta = await api.metas(fechaInicio, fechaFin, pais);
    const metas = [];
    const dataSemanaMetaDic = {};
    let index = 0;

    let moneda = pais == "GT" ? "Q" : "₡";
    commit("setMoneda", moneda);

    //convert data semana meta para falicl extraccion de data
    for (const data of dataSemanaMeta) {
      dataSemanaMetaDic[data.codigo_unidad] = data;
    }

    const addRow = (unidad) => {
      const meta = dataSemanaMetaDic[unidad.Codigo_Unidad].Meta_Unidad;
      const minimo = dataSemanaMetaDic[unidad.Codigo_Unidad].Minimo_Unidad;
      metas.push({
        codigo_unidad: unidad.Codigo_Unidad,
        nombre_unidad: unidad.Nombre_Unidad,
        no_semana: noSemana,
        pais: unidad.Pais,
        no_mes: noMes,
        no_año: anio,
        fecha_inicio: fechaInicio,
        fecha_fin: fechaFin,
        meta: meta ? Number(meta).toFixed(2) : "",
        minimo: minimo ? Number(minimo).toFixed(2) : "",
      });
    };

    for (const unidad of unidades) {
      //Gastronomia solo debe de ver las unidades tipo tipo in ('C','M','R')
      if (
        state.division == "Gastronomía" &&
        (unidad.tipo == "C" || unidad.tipo == "M" || unidad.tipo == "R")
      ) {
        //addRow(unidad);

        let meta_row = {
          codigo_unidad: unidad.Codigo_Unidad,
          nombre_unidad: unidad.Nombre_Unidad,
          no_semana: noSemana,
          pais: unidad.Pais,
          no_mes: noMes,
          no_año: anio,
          fecha_inicio: fechaInicio,
          fecha_fin: fechaFin,
          meta: "",
          minimo: "",
        };
        for (var i = 0; i < dataSemanaMeta.length; i++) {
          if (dataSemanaMeta[i].Codigo_Unidad == meta_row.codigo_unidad) {
            meta_row.meta = DecimalToCurrency(dataSemanaMeta[i].Meta_Unidad);
            meta_row.minimo = DecimalToCurrency(
              dataSemanaMeta[i].Minimo_Unidad
            );
          }
        }
        metas[index] = meta_row;
        index += 1;
      } //Moda solo debe de ver las unidades tipo tipo in ('T')
      else if (state.division == "Moda" && unidad.tipo == "T") {
        //addRow(unidad);

        let meta_row = {
          codigo_unidad: unidad.Codigo_Unidad,
          nombre_unidad: unidad.Nombre_Unidad,
          no_semana: noSemana,
          pais: unidad.Pais,
          no_mes: noMes,
          no_año: anio,
          fecha_inicio: fechaInicio,
          fecha_fin: fechaFin,
          meta: "",
          minimo: "",
        };
        for (var i = 0; i < dataSemanaMeta.length; i++) {
          if (dataSemanaMeta[i].Codigo_Unidad == meta_row.codigo_unidad) {
            meta_row.meta = DecimalToCurrency(dataSemanaMeta[i].Meta_Unidad);
            meta_row.minimo = DecimalToCurrency(
              dataSemanaMeta[i].Minimo_Unidad
            );
          }
        }
        metas[index] = meta_row;
        index += 1;
      }
    }
    commit("autoLlenarMeta", metas);
    commit("sumartotalesmetas");
  },
  async getUnidades(state) {
    state.dataUnidades = await api.unidades(state.pais);
  },
  async getMetaspais(state) {
    try {
      let arraydata = state.auxiparam.split("|");
      let datos_metas_unidad_in = await api.metas_unidad({
        fecha_inicio: arraydata[1],
        fecha_fin: arraydata[2],
        pais: state.dataPaisAsignado,
        codigo_unidad: arraydata[0],
      });
      state.MontoMeta = (state.dataPaisAsignado == 'GT') ? DecimalToCurrency(
        Number(datos_metas_unidad_in[0].Meta_Unidad)) :
        Number(datos_metas_unidad_in[0].Meta_Unidad);
      state.MontoMinimo = (state.dataPaisAsignado == 'GT') ? DecimalToCurrency(
        Number(datos_metas_unidad_in[0].Minimo_Unidad)) :
        Number(datos_metas_unidad_in[0].Minimo_Unidad);
    } catch (err) { }
  },
  async getEmpleados(state) {
    let arraydata = state.auxiparam.split("|");
    state.rowsEmpleados = await api.empleados_unidad(
      arraydata[0],
      state.dataPaisAsignado
    );
  },
  async getSemanaMeta(state) {
    try {
      state.dataSemanaMeta = await api.metas(
        state.fechaInicialMeta,
        state.fechaFinMeta,
        state.pais
      );
    } catch (err) { }
  },
  async getdetallemetas(state) {
    try {
      let arraydata = state.auxiparam.split("|");
      state.distribuciondetalle = await api.detalle_metas({
        fecha_inicio: arraydata[1],
        fecha_fin: arraydata[2],
        codigo_unidad: arraydata[0],
        pais: state.dataPaisAsignado,
      });
    } catch (err) { }
  },
  async getReporteByQuery({ commit }, { objeto, nombre_filtro, filtro }) {
    let headers = [];
    const ReporteResult = await api.reportes_list_tbl_vw(Reporte);

    headers = Object.keys(ReporteResult[0]).map((key) => ({
      text: key,
      value: key,
    }));

    commit("autoLlenarDataTablaReportes", ReporteResult);
    commit("autoLlenarDataTablaReportesHeaders", headers);
  },
  async getCalendarioFriedmanPorAnio({ commit }, { AnioFriedman }) {
    commit("mostrarLoading", { lanzar: true, texto: "Cargando" });
    let FriedmanArray = [];
    let MesesFilter = [];
    const Friedman = await metasBackendApi.calendario_friedman_table(
      AnioFriedman
    );

    Friedman.forEach((element) => {
      let DataFriedman = {
        Anio: element.Anio,
        No_Semana: element.No_Semana,
        No_Mes: element.No_Mes,
        Fecha_Inicial: element.Fecha_Inicial,
        Fecha_Final: element.Fecha_Final,
        Semana_Cerrada: String(element.Semana_Cerrada),
        FechaHoraApertura: element.FechaHoraApertura,
        SemanaFinal: element.SemanaFinal,
      };
      FriedmanArray.push(DataFriedman);
      MesesFilter.push({ value: element.No_Mes, text: element.No_Mes });
    });
    commit(
      "autoLlenarDataTablaFriedman",
      JSON.parse(JSON.stringify(FriedmanArray))
    );
    commit(
      "autoLlenarDataTablaFriedmanMesFilter",
      JSON.parse(JSON.stringify(MesesFilter))
    );
    commit("mostrarLoading", { lanzar: false, texto: "" });
  },
};
