import axios from "axios";

const URL_BASE_MAIL = 'https://func-notifications-service-prod.azurewebsites.net';
const axios_mail = axios.create({
  baseURL: URL_BASE_MAIL,
  timeout: 100000
})


export function sendMail(data) {
  return axios_mail.post('/api/sendMail', data, {
    contentType: 'application/json'
  })
}