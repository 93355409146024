<template>
  <v-app-bar color="black" app clipped-left>
    <!-- Hamburguer Icon -->
    <div
      v-if="isAuthenticated"
      id="ham-svg-icon"
      class="mr-4"
      @click="changeSidebar(!showSidebar)"
    >
      <svg
        class="ham hamRotate active"
        viewBox="0 0 100 100"
        onclick="this.classList.toggle('active')"
      >
        <path
          class="line top"
          d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"
        />
        <path class="line middle" d="m 30,50 h 40" />
        <path
          class="line bottom"
          d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"
        />
      </svg>
    </div>


    <!-- Título -->
    <v-toolbar-title>
      <router-link to="/home" style="cursor: pointer">
        <v-img :src="LOGO_SAUL" class="my-10" contain height="50" width="auto" />
      </router-link>
    </v-toolbar-title>
    <v-spacer></v-spacer>


    <!-- Options -->
    <div v-if="isAuthenticated" class="d-none d-md-flex">
      <!-- Tiempo de sesión restante -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
            @click.prevent="sessionTimeout" 
            v-bind="attrs" 
            v-on="on" 
            class="mx-3"
            text
            icon
            dark
          >
            <v-icon dark>mdi-timer-settings-outline </v-icon>
          </v-btn>
        </template>
        <span>Tiempo restante de sesión</span>
      </v-tooltip>

      <!-- Cerrar sesión -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
            @click.prevent="validAction" 
            v-bind="attrs" 
            v-on="on"
            class="mx-3"
            text
            icon
            dark
          >
            <v-icon dark>mdi-logout-variant</v-icon>
          </v-btn>
        </template>
        <span>Cerrar sesión</span>
      </v-tooltip>

      <CambiosSinGuardar @accept="logout" />

    </div>
  </v-app-bar>
</template>

<script>
import CambiosSinGuardar from '@/components/dialogs/CambiosSinGuardar.vue';
import auth from '@/utils/services/auth';
import LogoSaul from '@/assets/logoSaul.png';
import { mapState, mapMutations } from "vuex";

export default {
  name: "HeaderComponent",
  components: { CambiosSinGuardar },
  props: {
    WithSidebar: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      ONE_HOUR_IN_MILISEC: 3600000,
      LOGO_SAUL: LogoSaul
    }
  },
  computed: {
    ...mapState({
      showSidebar: "Sidebar",
      isAuthenticated: "Auth",
      expire: "Expire",
      VistaEnEdicion: "VistaEnEdicion"
    }),
    expTime() {
      return (this.ONE_HOUR_IN_MILISEC / 60) / 1000
    }
  },
  methods: {
    ...mapMutations(["changeSidebar", "setLogout"]),
    validAction() {
      if (this.VistaEnEdicion) {
        this.$store.commit(
          "showDialog",
          { who: "CambiosSinGuardar" }
        )
      } else {
        this.logout()
      }
    },
    async logout() {
      try {
        const logout = await auth.SignOut()

        if (logout) {
          this.setLogout()

          window.location.replace('/')
        }
      } catch (ex) {
        const data = {
          visible: true,
          data: {
            type: "error",
            title: "No se pudo cerrar la sesión",
            description: ex
          },
        };

        this.$store.commit("showAlert", data);
      }
    },
    sessionTimeout() {
      const data = {
        visible: true,
        data: {
          type: "info",
          title: `La sesión expirará en: ${this.expTime.toFixed(2)} minutos`,
          description: `La sesión expirará en: ${this.expTime.toFixed(2)} minutos`
        },
      };

      this.$store.commit("showAlert", data);
    }
  },
  created() {
    setInterval(() => {
      this.ONE_HOUR_IN_MILISEC = this.ONE_HOUR_IN_MILISEC - 1000;
    }, 1000);

    setTimeout(() => {
      this.logout();
    }, this.ONE_HOUR_IN_MILISEC)
  }
};
</script>

<style lang="scss" scoped>
#ham-svg-icon {
  height: 100%;
  width: 3.5rem;
  .ham {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 400ms;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .hamRotate.active {
    transform: rotate(45deg);
  }
  .line {
    fill: none;
    transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
    stroke: #fff;
    stroke-width: 5.5;
    stroke-linecap: round;
  }
  .ham .top {
    stroke-dasharray: 40 160;
  }
  .ham .middle {
    stroke-dasharray: 40 142;
    transform-origin: 50%;
    transition: transform 400ms;
  }
  .ham .bottom {
    stroke-dasharray: 40 85;
    transform-origin: 50%;
    transition: transform 400ms, stroke-dashoffset 400ms;
  }
  .ham.active .top {
    stroke-dashoffset: -64px;
  }
  .ham.active .middle {
    transform: rotate(90deg);
  }
  .ham.active .bottom {
    stroke-dashoffset: -64px;
  }
}
</style>
