import Vue from 'vue'

import App from './App.vue'

import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import VueExcelEditor from 'vue-excel-editor'



Vue.config.productionTip = false
loadFonts();

Vue.use(VueExcelEditor);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')