import axios from "axios";

const URL_BASE_AD = 'https://func-aad-service-prod.azurewebsites.net';
const axios_ad = axios.create({
  baseURL: URL_BASE_AD,
  timeout: 100000
})


export function doget_AD(controller) {
  return axios_ad.get(controller)
}