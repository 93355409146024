export default {
  Auth: false, // Sesión iniciada
  MetasApiToken: null, // Token generado por api de metas
  Loading: true, // Loader activo
  Sidebar: true, // Sidebar es visible
  Expire: null, // { userName, expired }
  UserInfo: null, // { userName, name, rol }
  UserPermissions: null, // Permisos del usuario
  /* UserEssentials: {
      information: { IdPlaza Evolution, IdUsuario, NombrePlaza }
      permissions: []
      routes_with_access: [{ icon, path, title }]
  }
  */
  UserEssentials: null,
  Metas: null,
  MontoMeta: "",
  MontoMinimo: "",
  SumaMeta: 0,
  SumaMinimo: 0,
  Moneda: null,
  Alert: {
    visible: false, // Muestra una alerta del sistema
    data: {
      // Datos y tipo de la alerta
      type: "error",
      title: "",
      subtitle: "",
      description: "",
    },
  },
  VistaEnEdicion: false,
  Dialogs: {
    ConfirmarOperacion: null, // Modal "Confirmar Operación" es visible,
    CambiosSinGuardar: null, // Modal "Confirmar acción con cambios sin guardar" es visible
    data: {
      // Datos y tipo de la modal
      type: "",
      title: "",
      subtitle: "",
      description: "",
      buttons: {
        // Botones activos en la modal
        deny: true,
        accept: true,
      },
    },
  },
  /* :start: calendario friedman :start: */
  ListadoSemanasFriedman: null,
  ListadoAniosDisponibles: null,
  ListadoMeses: [
    { text: "Enero", value: 1 },
    { text: "Febrero", value: 2 },
    { text: "Marzo", value: 3 },
    { text: "Abril", value: 4 },
    { text: "Mayo", value: 5 },
    { text: "Junio", value: 6 },
    { text: "Julio", value: 7 },
    { text: "Agosto", value: 8 },
    { text: "Septiembre", value: 9 },
    { text: "Octubre", value: 10 },
    { text: "Noviembre", value: 11 },
    { text: "Diciembre", value: 12 },
  ],
  semanas_x_anio: [],
  semanas_x_mes: [],
  semana_seleccionada: {},
  /* :end: calendario friedman :end: */
  listadoPlazasDisponibles: [],
  listadoPlazasActivas: [],
  /*
   ** DELEGATE FROM V1.4.2
   */
  //simboloMoneda: undefined, Reemplazado por: Moneda
  //montometa: "", Reemplazado por MontoMeta
  //montominimo: "", Reemplazado por SumaMinimo
  //userAccount: undefined, Reemplazado por UserInfo
  //userGraphInfo: undefined, Reemplazado por UserPermissions
  //sumameta: 0, Reemplazado por SumaMeta
  //sumaminimo: 0, Reemplazado por SumaMinimo
  DataTablaFriedman: [],
  DataTablaFriedmanMesFilter: [],
  DataTablaReportes: [],
  DataTablaReportesHeader: [],
  UnidadesDistribucion: null,
  UnidadesAsignadasConFormato: null,
  CatalogoUnidades: null,
  dataUnidadesasignadas: [],
  dataPaisAsignado: undefined,
  division: undefined,
  appTitle: "Saul E Mendez",
  verDistribuccion: undefined,
  InsertarDistribuccion: undefined,
  EliminarDistribuccion: undefined,
  ActualizarDistribuccion: undefined,
  verMeta: undefined,
  InsertarMeta: undefined,
  EliminarMeta: undefined,
  ActualizarMeta: undefined,
  verAbrirCerrarSemana: undefined,
  verRolPermisos: undefined,
  verCrearEditarAnioFriedman: undefined,
  verReasignarCuentasMesero: undefined,
  verVentasSAPMicros: undefined,
  unidadesasignadas: [],
  dataUnidades: [],
  dataPais: [],
  dataPaises: [],
  rows: [],
  rowsUsuarios: [],
  plazaLista: [],
  UnidadesAll: [],
  rowsPlazas: [],
  rowsUsuariosAD: [],
  rowsCatalogosDivisiones: [],
  rowsCatalogosPaises: [],
  rowsIn: [],
  dataDistribuccion_edit: [],
  dataDistribuccion: [],
  pais: "",
  fechaInicialMeta: "",
  fechaFinMeta: "",
  dataSemanaMeta: [],
  alertaNoMeta: false,
  nosemana: "",
  nomes: "",
  noanio: "",
  index: 0,
  objeDataUnidad: [],
  auxiparam: "",
  rowsEmpleados: [],
  distribuciondetalle: [],
  sumametadistribucion: "0.00",
  sumaminimodistribucion: "0.00",
  loading: false,
  verPencil: false,
  verMetaMercado: false, 
  codigoMercado: "",
  AnioMercado: 0,
  MesMercado: 0,
  FechaFinMercado: "",
};
