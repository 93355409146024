<template>
  <v-alert
    v-model="Alert.visible"
    id="notification"
    elevation="2"
    transition="slide-x-reverse-transition"
    :type="Alert.data.type"
    border="left"
    max-width="50vw"
    colored-border
    dismissible
  >
    <div class="text-h6">{{ Alert.data.title }}</div>
    <v-divider></v-divider>
    <v-expansion-panels class="mt-2" accordion>
      <v-expansion-panel>
        <v-expansion-panel-header>
          {{ Alert.data.subtitle || "Descripción del mensaje:"}}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          {{ Alert.data.description }}
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-alert>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "AlertaGenerica",
  watch: {
    Alert(to, from) {
      if (to !== from) {
        this.closeDialog();
      }
    },
  },
  computed: {
    ...mapState(["Alert"]),
  },
  methods: {
    ...mapMutations(["closeDialog"]),
  },
};
</script>

<style lang="scss" scoped>
#notification {
  position: absolute;
  top: 2rem;
  right: 0;
  z-index: 100000;
}
</style>
