import store from "@/store";

export function DecimalToCurrency(value) {
  let decimal = 0.00
  const moneda = store.state.Moneda

  if (typeof(value) === 'string') {
    decimal = parseFloat(value).toFixed(2)
  }

  if (typeof(value) === 'number') {
    decimal = value.toFixed(2)
  }
  
  if (moneda === 'Q') {
    return Intl.NumberFormat("es-GT", { style: "currency", currency: "GTQ", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(decimal);
  }

  if (moneda === '₡') {
    return Intl.NumberFormat("es-CR", { style: "currency", currency: "CRC", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(decimal);
  }

  return Intl.NumberFormat("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(decimal);
}

export function CurrencyToDecimal(value = "Q 0.00") {
  let currency = "Q 0.00"

  if (typeof(value) === 'string') {
    currency = value
  }

  if (typeof(value) === 'number') {
    currency = toString(value)
  }

  return Number(currency.replace(/[^0-9.-]+/g,""));
}