// DEPENDENCIES
import axios from 'axios'

// VARIANTS
import { doget_AD } from './aad'
import { sendMail } from './mail'

// STORES
import store from '@/store'

// MODULES
import ApiMetas from "./modules/app-metas";


export default {
  from,
  login,
  doget_AD,
  sendMail,
  ...ApiMetas
}

// CONFIGURATIONS
const URL_BASE =  'https://metas-api.equiposaul.com';
const axios_metas = axios.create({
  baseURL: URL_BASE,
  timeout: 100000000
})

export function initialize_api() {
  const token = store.state.MetasApiToken;

  if (!token) return

  axios_metas.defaults.headers.common["x-access-tokens"] = token

  axios_metas.interceptors.response.use(
    async response => {
      const originalRequest = response.config

      if (response?.data?.status === 401 && !originalRequest._auth_retry) {
          originalRequest._auth_retry = true
          await store.dispatch('loginApiMetas')
          const token = store.state.MetasApiToken

          axios_metas.defaults.headers.common["x-access-tokens"] = token
          return axios_metas(originalRequest)
      }

      return response
    },
    async error => {
      try {
        const originalRequest = error.config

        if (error.response.status === 500 && !originalRequest._server_retry) {
          originalRequest._server_retry = true

          return axios_metas(originalRequest)
        }

        if (error.response.status === 401 && !originalRequest._auth_retry) {
          originalRequest._auth_retry = true
          await store.dispatch('loginApiMetas')
          const token = store.state.MetasApiToken

          axios_metas.defaults.headers.common["x-access-tokens"] = token
          return axios_metas(originalRequest)
        }

        throw error
      } catch (ex) {
        return Promise.reject(error)
      }
    }
  )
}

// CONSTRUCTOR
function from(controller) {
  const map = {
    get(params = {}) {
      return axios_metas.get(controller, { params })
    },
    post(data, params = {}) {
      return axios_metas.post(controller, data, {
        params,
        contentType: 'application/json'
      })
    },
    put(data, params = {}) {
      return axios_metas.put(controller, data, {
        params,
        contentType: 'application/json'
      })
    },
    patch(data, params = {}) {
      return axios_metas.patch(controller, data, { params })
    },
    del(data, params = {}) {
      return axios_metas.delete(controller,{params})
      /*
      return axios_metas.delete(controller, data, {
        params,
        contentType: 'application/json'
      })
      */
    }
  }

  return map
}

async function login() {
  const USERNAME = process.env.VUE_APP_METAS_USERNAME;
  const PASSWORD = process.env.VUE_APP_METAS_PWD;
  const LOGIN_PATH = `${URL_BASE}/auth/login`;
  const CREDENTIALS = window.btoa(`${USERNAME}:${PASSWORD}`);
  const HEADERS = { Authorization: `Basic ${CREDENTIALS}` };

  return axios({
    method: "get",
    url: LOGIN_PATH,
    headers: HEADERS
  });

}
