/* FORMATO DEFAULT DEL PARAMETRO
  listado_semanas = {
    Anio: 2013,
    FechaHoraApertura: null,
    Fecha_Final: "2013-01-06 00:00:00",
    Fecha_Inicial: "2012-12-31 00:00:00",
    No_Mes: 1,
    No_Semana: 1,
    SemanaFinal: false,
    Semana_Cerrada: 1,
    idEmp: null
  }
*/

/* FORMATO RETORNO
  return {
    listado_anios: [Anio(0), Anio(1) ...],
    listado_semanas: {
      Anio(0): [{
          FechaHoraApertura: null,
          Fecha_Final_DMY: "06-01-2013",
          Fecha_Final_YMD: "2013-01-06",
          Fecha_Inicial_YMD: "2012-12-31",
          Fecha_Final_DMY: "31-12-2012",
          No_Semana: 1,
          SemanaFinal: false,
          Semana_Cerrada: 1,
        }]
    },
  }
*/

export default function (listado_semanas_friedman) {
  const response = {
    listado_anios: [],
    listado_semanas: {}
  };

  const Anios = listado_semanas_friedman.map(semana => {
    return semana.Anio
  })

  response.listado_anios = Anios.filter((value, index, array) => {
    return array.indexOf(value) === index;
  })

  response.listado_anios.forEach((anio) => {
    let semanas_x_anio = listado_semanas_friedman.filter(semana => semana.Anio === anio)
    let semanas_w_formato = semanas_x_anio.map(semana => {
      let Fecha_Inicial_YMD = semana.Fecha_Inicial.replace(" 00:00:00", "");
      let Fecha_Final_YMD = semana.Fecha_Final.replace(" 00:00:00", "");
      let Fecha_Inicial_DMY = Fecha_Inicial_YMD.split("-").reverse().join("-");
      let Fecha_Final_DMY = Fecha_Final_YMD.split("-").reverse().join("-");
      let Status_Semana = semana.Semana_Cerrada === -1 ? 'Pendiente' : semana.Semana_Cerrada === 0 ? 'Abierta' : semana.Semana_Cerrada === 1 ? 'Cerrada' : 'Desconocida'

      return {
        text: `${semana.No_Semana} - ${Fecha_Inicial_DMY} Al ${Fecha_Final_DMY} (${Status_Semana})`,
        value: {
          Fecha_Inicial_YMD,
          Fecha_Final_YMD,
          Fecha_Inicial_DMY,
          Fecha_Final_DMY,
          Status_Semana,
          FechaHoraApertura: semana.FechaHoraApertura,
          Anio: anio,
          No_Mes: semana.No_Mes,
          No_Semana: semana.No_Semana,
          SemanaFinal: semana.SemanaFinal,
          Semana_Cerrada: semana.Semana_Cerrada,
        }
      }
    })

    response.listado_semanas[anio] = semanas_w_formato;
  })

  return response;
}