<template>
  <div id="saul-metas-app">
    <loader-component />
    <component :is="layout">
      <router-view :key="$route.fullPath" v-slot="{ Component }">
        <transition name="fade">
          <component :is="Component" />
        </transition>
      </router-view>
    </component>
  </div>
</template>

<script>
/* LAYOUTS */
import NoneLayout from '@/layouts/NoneLayout.vue';
import MainLayout from '@/layouts/MainLayout.vue';
import OnlyHeaderLayout from '@/layouts/OnlyHeaderLayout.vue';

/* LOADER */
import LoaderComponent from '@/components/core/Loader.vue';


export default {
  name: "SaulMetasApp",
  components: {
    NoneLayout,
    MainLayout,
    OnlyHeaderLayout,
    LoaderComponent
  },
  computed: {
    layout() {
      /*
      ** Layout actual según la propiedad meta del router
      **
      ** Layouts disponibles:
      ** only-header, main, none
      */
      return `${this.$route.meta.layout || "none"}-layout`;
    }
  }
}
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;600;700&display=swap");

/* Reset styles for main container */
* {
  margin: 0;
  padding: 0;
  font-family: "Mulish", sans-serif !important;
}

/* App size of display */
#saul-metas-app {
  height: 100vh;
  width: 100vw;
}

/* Main background color */
.v-main__wrap {
  background-color: #e5e5e5;
}

/* Reset styles for scrollbar in early browser's */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #aeb7bd;
  border: 0px none #aeb7bd;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: #aeb7bd;
}
::-webkit-scrollbar-thumb:active {
  background: #aeb7bd;
}
::-webkit-scrollbar-track {
  background: #ffffff;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #ffffff;
}
::-webkit-scrollbar-track:active {
  background: #ffffff;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
</style>
