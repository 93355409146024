

export default {
  AbrirCerrarSemana: {
    title: "Abrir/Cerrar Semana",
    path: "/abrir-cerrar-semana",
    icon: "mdi-calendar-clock",
  },
  PuedeAsignarRolesPermisos: {
    title: "Roles y Permisos",
    path: "/roles-permisos",
    icon: "mdi-account",
  },
  CrearEditarAnioFriedman: {
    title: "Año Friedman",
    path: "/anio-friedman",
    icon: "mdi-calendar",
  },
  ReasignarCuentaMesero: {
    title: "Reasignar Cuentas",
    path: "/reasignar-cuentas-mesero",
    icon: "mdi-sync",
  },
  ActualizarVentasSapMicros: {
    title: "SAP - MICROS",
    path: "/ventas-sap-micros",
    icon: "mdi-table",
  },
  LeerMeta: {
    title: "Meta",
    path: "/meta",
    icon: "mdi-dns",
  },
  LeerDistribucion: {
    title: "Distribucion",
    path: "/distribucion-metas",
    icon: "mdi-calendar",
  },
  ReportesTH: {
    title: "Reportes TH",
    path: "/reportes-th",
    icon: "mdi-file-document-multiple-outline",
  },
  ReportesWeb: {
    title: "Reportes Web",
    path: "/reportes-web",
    icon: "mdi-file-document-multiple-outline",
  },
  TrasladosDePersonal: {
    title: "Traslados de Personal",
    path: "/traslado-personal",
    icon: "mdi-swap-horizontal",
  },
   SincronizacionR1: {
    title: "Sincronización con R1",
    path: "/sincronizacion-sap-r1",
    icon: "mdi-account-switch-outline",
  }, 
}
