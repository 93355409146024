import { DecimalToCurrency } from "@/utils/helpers/CurrencyPrettier";

export default {
  setListadoPlazas(state, { plazas_disponibles, plazas_activas }) {
    state.listadoPlazasDisponibles = plazas_disponibles
    state.listadoPlazasActivas = plazas_activas
  },
  setCatalogoUnidades(state, payload) {
    state.CatalogoUnidades = payload
  },
  setUnidadesDistribucion(state, payload) {
    state.UnidadesDistribucion = payload
  },
  setUnidadesDistribucionFormato(state, payload) {
    state.UnidadesAsignadasConFormato = payload
  },

  /* start:: calendario friedman ::start */
  cleanCalendarioFriedman(state) {
    state.ListadoSemanasFriedman = null
    state.ListadoAniosDisponibles = null
    state.semanas_x_anio = []
    state.semanas_x_mes = []
    state.semana_seleccionada = {}
  },
  setCalendarioFriedman(state, { listado_anios, listado_semanas }) {
    state.ListadoSemanasFriedman = listado_semanas
    state.ListadoAniosDisponibles = listado_anios
  },

  setSemanasXAnio(state, payload) {
    state.semanas_x_anio = payload
  },
  setSemanasXMes(state, payload) {
    state.semanas_x_mes = payload
  },
  setSemanaSeleccionada(state, payload) {
    state.semana_seleccionada = payload
  },
  /* end:: calendario friedman ::end */

  changeSidebar(state, payload) {
    state.Sidebar = payload;
  },
  showLoading(state, payload) {
    state.Loading = payload;
  },
  setExpire(state, payload) {
    state.Expire = payload;
  },
  setUserGraphDetails(state, payload) {
    state.UserGraphDetails = payload;
  },
  setUserEssentials(state, payload) {
    state.UserEssentials = payload;
  },
  setMetasApiToken(state, payload) {
    state.MetasApiToken = payload;
  },
  setLogin(state, { account }) {
    state.UserInfo = account;
    state.Auth = true;
  },
  setLogout(state) {
    state.UserInfo = false;
    state.UserPermissions = false;
    state.Auth = false;
  },
  setPermissions(state, payload) {
    state.UserPermissions = payload;
  },
  toggleVistaEnEdicion(state) {
    state.VistaEnEdicion = !state.VistaEnEdicion
  },
  showAlert(state, payload) {
    state.Alert = payload;
  },
  showDialog(state, { who, data }) {
    state.Dialogs[who] = true;
    state.Dialogs.data = data;
  },
  closeDialog(state, who) {
    state.Dialogs[who] = false;
  },
  setMoneda(state, payload) {
    state.Moneda = payload;
  },
  setMetasTotales(state, payload) {
    if (payload === null) {
      state.Metas = null
      state.SumaMeta = 0
      state.SumaMinimo = 0

    } else {    
      state.Metas = payload

      let sumametaIn = 0;
      let sumaminimoIn = 0;
  
      for (var i = 0; i < payload.length; i++) {

        let meta = payload[i].meta;
        let minimo = payload[i].minimo;
        
        /*
        let meta = ((payload[i].meta).replace(",", "").replace(",", "").replace(",", "")).replace(state.Moneda, "");
        let minimo = ((payload[i].minimo).replace(",", "").replace(",", "").replace(",", "")).replace(state.Moneda, "");
        */

        if (meta != "") {
          sumametaIn = parseFloat(sumametaIn) + parseFloat(meta);
          sumaminimoIn = parseFloat(sumaminimoIn) + parseFloat(minimo);
        }
      }
  
      state.SumaMeta = (state.Moneda == 'Q') ? DecimalToCurrency(sumametaIn) : (sumametaIn);
      state.SumaMinimo = (state.Moneda == 'Q') ? DecimalToCurrency(sumaminimoIn) : (sumaminimoIn);
    }
  },


  /* ----------------------------------------- */

 
  setUserDivision: (state, payload) => {
    const { division } = payload;
    state.division = division;
  },

  setPencil(state, payload) {
    state.verPencil = payload;
  },

  setverMetaMercado(state, payload) {
    state.verMetaMercado = payload; 
  },

  setcodigoMercado(state, payload) {
    state.codigoMercado = payload; 
  },
  
  setAnioMercado(state, payload) {
    state.AnioMercado = payload; 
  },

  setMesMercado(state, payload) {
    state.MesMercado = payload; 
  },

  setFechaFinMercado(state, payload) {
    state.FechaFinMercado = payload; 
  },

  setUserPaisAsignado: (state, payload) => {
    const { dataPaisAsignado } = payload;
    state.dataPaisAsignado = dataPaisAsignado;
  },

  setUserUnidadesasignadas: (state, payload) => {
    const { dataUnidadesasignadas } = payload;
    state.dataUnidadesasignadas = dataUnidadesasignadas;
  },

  setUserPaises: (state, payload) => {
    const { dataPaises } = payload;
    let paisIN = dataPaises;

  },


  setUserPais: (state, payload) => {
    const { dataPais } = payload;
    let paisIN = dataPais;
    for (let inPais of paisIN) {
      if (inPais == "GT") {
        state.dataPais[state.dataPais.length] = {
          id_pais: "GT",
          nombre_pais: "Guatemala",
        };
      }
      if (inPais == "CR") {
        state.dataPais[state.dataPais.length] = {
          id_pais: "CR",
          nombre_pais: "Costa Rica",
        };
      }
    }
  },


  setUserAbrirCerrarSemana: (state, payload) => {
    const { verAbrirCerrarSemana } = payload;
    let menus = state.menuItems;
    let insert = true;
    state.menuItems = [];
    state.verAbrirCerrarSemana = verAbrirCerrarSemana;
    if (state.verAbrirCerrarSemana == true) {
      let registro = {
        title: "Abrir/Cerrar Semana",
        path: "/AbrirCerrarSemana",
        icon: "mdi-calendar-clock",
        action: "console",
      };
      for (let menu of menus) {
        if (menu.title == "Abrir/Cerrar Semana") {
          insert = false;
        }
      }
      if (insert == true) {
        state.menuItems[state.menuItems.length] = registro;
      }
    }
    for (let menu of menus) {
      state.menuItems[state.menuItems.length] = menu;
    }
  },

  setUserRolPermisos: (state, payload) => {
    const { verRolPermisos } = payload;
    let menus = state.menuItems;
    let insert = true;
    state.menuItems = [];
    state.verRolPermisos = verRolPermisos;

    if (state.verRolPermisos == true) {
      let registro = {
        title: "Roles y Permisos",
        path: "/RolesPermisos",
        icon: "mdi-account",
        action: "console",
      };
      for (let menu of menus) {
        if (menu.title == "Roles y Permisos") {
          insert = false;
        }
      }
      if (insert == true) {
        state.menuItems[state.menuItems.length] = registro;
      }
    }
    for (let menu of menus) {
      state.menuItems[state.menuItems.length] = menu;
    }
  },

  setCrearEditarAnioFriedman: (state, payload) => {
    const { verCrearEditarAnioFriedman } = payload;
    let menus = state.menuItems;
    let insert = true;
    state.menuItems = [];
    state.verCrearEditarAnioFriedman = verCrearEditarAnioFriedman;

    if (state.verCrearEditarAnioFriedman == true) {
      let registro = {
        title: "Año Friedman",
        path: "/AnioFriedman",
        icon: "mdi-calendar",
        action: "console",
      };
      for (let menu of menus) {
        if (menu.title == "Año Friedman") {
          insert = false;
        }
      }
      if (insert == true) {
        state.menuItems[state.menuItems.length] = registro;
      }
    }
    for (let menu of menus) {
      state.menuItems[state.menuItems.length] = menu;
    }
  },

  setReasignarCuentasMesero: (state, payload) => {
    const { verReasignarCuentasMesero } = payload;
    let menus = state.menuItems;
    let insert = true;
    state.menuItems = [];
    state.verReasignarCuentasMesero = verReasignarCuentasMesero;

    if (state.verReasignarCuentasMesero == true) {
      let registro = {
        title: "Reasignar Cuentas",
        path: "/ReasignarCuentasMesero",
        icon: "mdi-sync",
        action: "console",
      };
      for (let menu of menus) {
        if (menu.title == "Reasignar Cuentas") {
          insert = false;
        }
      }
      if (insert == true) {
        state.menuItems[state.menuItems.length] = registro;
      }
    }
    for (let menu of menus) {
      state.menuItems[state.menuItems.length] = menu;
    }
  },

  setVentasSAPMicros: (state, payload) => {
    const { verVentasSAPMicros } = payload;
    let menus = state.menuItems;
    let insert = true;
    state.menuItems = [];
    state.verVentasSAPMicros = verVentasSAPMicros;

    if (state.verVentasSAPMicros == true) {
      let registro = {
        title: "Ventas SAP Micros",
        path: "/VentasSAPMicros",
        icon: "mdi-table",
        action: "console",
      };
      for (let menu of menus) {
        if (menu.title == "Ventas SAP Micros") {
          insert = false;
        }
      }
      if (insert == true) {
        state.menuItems[state.menuItems.length] = registro;
      }
    }
    for (let menu of menus) {
      state.menuItems[state.menuItems.length] = menu;
    }
  },

  setUserMeta: (state, payload) => {
    const { verMeta } = payload;
    let menus = state.menuItems;
    let insert = true;
    state.menuItems = [];
    state.verMeta = verMeta;
    if (state.verMeta == true) {
      let registro = {
        title: "Meta",
        path: "/meta",
        icon: "mdi-dns",
        action: "console",
      };
      for (let menu of menus) {
        if (menu.title == "Meta") {
          insert = false;
        }
      }
      if (insert == true) {
        state.menuItems[state.menuItems.length] = registro;
      }
    }

    for (let menu of menus) {
      state.menuItems[state.menuItems.length] = menu;
    }
  },

  setUserDistribuccion: (state, payload) => {
    const { verDistribuccion } = payload;
    let menus = state.menuItems;
    let insert = true;
    state.menuItems = [];
    state.verDistribuccion = verDistribuccion;
    if (state.verDistribuccion == true) {
      let registro = {
        title: "Distribucion",
        path: "/distribucion-metas",
        icon: "mdi-calendar",
        action: "console",
      };
      for (let menu of menus) {
        if (menu.title == "Distribucion") {
          insert = false;
        }
      }
      if (insert == true) {
        state.menuItems[state.menuItems.length] = registro;
      }
    }
    for (let menu of menus) {
      state.menuItems[state.menuItems.length] = menu;
    }
  },

  toggleLoading(state) {
    state.loading = !state.loading;
  },

  autoLlenarMeta(state, metas) {
    state.rows = metas;
  },

  autoLlenarplazaLista(state, plazas) {
  state.plazaLista =  plazas;
  },
  
  autoLlenarUnidadesAll(state, unidades) {
    state.UnidadesAll =  unidades;
    },

  autoLlenarUsuarios(state, usuarios) {

    let arrayusuariosTemp = [];
    for (let index = 0; index < usuarios.length; index++) {
      let usuariosTemp = new Object();
      if (usuarios[index].EstadoUsuario == 1) {
        usuariosTemp.EstadoUsuario = true;
      }
      else {
        usuariosTemp.EstadoUsuario = false;
      }
      usuariosTemp.CodigoEmpleadoEvolution = usuarios[index].CodigoEmpleadoEvolution;
      usuariosTemp.EmpresaEmpleadoEvolution = usuarios[index].EmpresaEmpleadoEvolution;
      usuariosTemp.PuedeAsignarRolesPermisos = usuarios[index].PuedeAsignarRolesPermisos;
      usuariosTemp.IdPlazaEvolution = usuarios[index].IdPlazaEvolution;
      usuariosTemp.IdUsuario = usuarios[index].IdUsuario;
      usuariosTemp.NombrePlaza = usuarios[index].NombrePlaza;
      usuariosTemp.Usuario = usuarios[index].Usuario;
      arrayusuariosTemp.push(usuariosTemp)
    }

    if (state.UserPermissions.permissions.includes("PuedeAsignarRolesPermisos")){

      state.rowsUsuarios = arrayusuariosTemp;
    
    }

  },

  autoLlenarPuestos(state, puestos) {
    state.rowsPlazas = puestos;
  },

  autoLlenarUsuariosAD(state, usuarios) {
    state.rowsUsuariosAD = usuarios;
  },

  autoLlenarCatalogosDivisiones(state, datos){
    state.rowsCatalogosDivisiones = datos;
  },
  
  autoLlenarCatalogosPaises(state, datos){
    state.rowsCatalogosPaises = datos;
  },   

  setMoneda(state, moneda) {
    state.Moneda = moneda;
  },

  setMetaMinimodistribucion(state, { meta, minimo }) {
    state.MontoMeta = meta;
    state.MontoMinimo = minimo;
  },

  InsertRowMeta(state) {
    let meta_row = {
      codigo_unidad: state.objeDataUnidad.Codigo_Unidad,
      nombre_unidad: state.objeDataUnidad.Nombre_Unidad,
      no_semana: state.nosemana,
      pais: state.objeDataUnidad.Pais,
      no_mes: state.nomes,
      no_año: state.noanio,
      fecha_inicio: state.fechaInicialMeta,
      fecha_fin: state.fechaFinMeta,
      meta: "",
      minimo: "",
    };

    for (var i = 0; i < state.dataSemanaMeta.length; i++) {
      if (
        state.dataSemanaMeta[i].Anio == meta_row.no_año &&
        state.dataSemanaMeta[i].Codigo_Unidad == meta_row.codigo_unidad &&
        state.dataSemanaMeta[i].Mes == meta_row.no_mes &&
        state.dataSemanaMeta[i].No_Semana == meta_row.no_semana &&
        state.dataSemanaMeta[i].Pais == meta_row.pais
      ) {
        meta_row.meta = Number(state.dataSemanaMeta[i].Meta_Unidad).toFixed(
          2
        );
        meta_row.minimo = Number(
          state.dataSemanaMeta[i].Minimo_Unidad
        ).toFixed(2);
      }
    }

    state.rowsIn[state.index] = meta_row;

    state.index += 1;
  },

  cleardistribucion(state) {
    state.MontoMeta = "";
    state.MontoMinimo = "";
    state.sumametadistribucion = 0;
    state.sumaminimodistribucion = 0;
    state.dataDistribuccion = [];
    state.alertaNoMeta = false;
  },

  clearalertaNoMeta(state){
    state.alertaNoMeta = false;
  },

  clearLlenarMeta(state) {
    state.sumameta = 0;
    state.sumaminimo = 0;
    state.rows = [];
    state.Moneda = undefined;
  },

  autoLlenarDistribucion(state, distribucion) {
    state.dataDistribuccion = distribucion;
  },

  sumartotalesdistribucion(state, { metadistribucion, minimodistribucion }) {
    state.sumametadistribucion = metadistribucion;
    state.sumaminimodistribucion = minimodistribucion;
  },

  
  autoLlenarDataTablaReportes(state, reportes) {
    state.DataTablaReportes = reportes;
  },
  autoLlenarDataTablaReportesHeaders(state, headers) {
    state.DataTablaReportesHeader = headers;
  },
  autoLlenarDataTablaFriedman(state, friedman) {
    state.DataTablaFriedman = friedman;
  },
  autoLlenarDataTablaFriedmanMesFilter(state, mes) {
    state.DataTablaFriedmanMesFilter = mes;
  },
};
