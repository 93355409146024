import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Login",
    meta: {
      layout: "only-header",
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/LoginView.vue"),
  },
  {
    path: "/home",
    name: "Home",
    meta: {
      layout: "main",
      requireAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/HomeView.vue"),
  },
  {
    path: "/meta",
    name: "Meta",
    meta: {
      layout: "main",
      requireAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "metas" */ "@/views/MetasView.vue"),
  },
  {
    path: "/roles-permisos",
    name: "Roles y Permisos",
    meta: {
      layout: "main",
      requireAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "rolespermisos" */ "@/views/RolesYPermisosView.vue"),
  },
  {
    path: "/anio-friedman",
    name: "Año Friedman",
    meta: {
      layout: "main",
      requireAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "aniofriedman" */ "@/views/AnioFriedmanView.vue"),
  },
  {
    path: "/reportes-th",
    name: "ReportesTH",
    meta: {
      layout: "main",
      requireAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "reportesth" */ "@/views/ReportesView.vue"),
  },  
  {
    path: "/reportes-web",
    name: "ReportesWeb",
    meta: {
      layout: "main",
      requireAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "reportesweb" */ "@/views/ReportesWebView.vue"),
  },
  {
    path: "/traslado-personal",
    name: "TrasladoPersonal",
    meta: {
      layout: "main",
      requireAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "trasladopersonal" */ "@/views/TrasladoPersonasView.vue"),
  },
  {
    path: "/reasignar-cuentas-mesero",
    name: "Reasignar Cuentas Mesero",
    meta: {
      layout: "main",
      requireAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "cuentasmeseros" */ "@/views/ReasignarMeseroView.vue"),
  },
  {
    path: "/ventas-sap-micros",
    name: "SAP - Micros",
    meta: {
      layout: "main",
      requireAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "ventasmicros" */ "@/views/VentasSapMicrosView.vue"),
  },
  {
    path: "/abrir-cerrar-semana",
    name: "AbrirCerrarSemana",
    meta: {
      layout: "main",
      requireAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "semanaestado" */ "@/views/ToggleSemanaView.vue"),
  },
  {
    path: "/distribucion-metas",
    name: "Distribucuin metas",
    meta: {
      layout: "main",
      requireAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "distribucion" */ "@/views/DistribucionView.vue"),
  },
  {
    path: "/sincronizacion-sap-r1",
    name: "Sincronizador R1",
    meta: {
      layout: "main",
      requireAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "sincronizadorr1" */ "@/views/SincronizadorR1View.vue"),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAuth)) {
    if (store.state.Auth) {
      next();
    } else {
      next({ name: "Login" });
    }
  } else {
    next();
  }
});

export default router
