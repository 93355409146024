<template>
  <v-app>
      <HeaderComponent :WithSidebar="false" />
      <v-main>
          <v-container fluid>
              <AlertsComponent />
              <slot></slot>
          </v-container>
      </v-main>
  </v-app>
</template>

<script>
/* CORE */
import AlertsComponent from "@/components/core/Alerts.vue";
import HeaderComponent from "@/components/core/Header.vue";

export default {
  name: "OnlyHeaderLayout",
  components: {
    HeaderComponent,
    AlertsComponent
  }
}
</script>
