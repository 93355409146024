<template>
  <v-app>
      <HeaderComponent id="header" />
      <SidebarComponent id="sidebar" />
      <v-main>
          <v-container fluid>
              <AlertsComponent />
              <slot id="view-container"></slot>
          </v-container>
      </v-main>
      <FooterComponent />
  </v-app>
</template>

<script>
/* CORE */
import HeaderComponent from "@/components/core/Header.vue";
import SidebarComponent from "@/components/core/Sidebar.vue";
import AlertsComponent from "@/components/core/Alerts.vue";
import FooterComponent from "@/components/core/Footer.vue";

export default {
  name: "MainLayout",
  components: {
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    AlertsComponent
  }
}
</script>
