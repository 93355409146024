<template>
  <v-app>
      <slot></slot>
  </v-app>
</template>

<script>
export default {
  name: "NoneLayout"
}
</script>
