<template>
  <v-dialog v-model="Dialogs.CambiosSinGuardar" max-width="500" persistent>
    <v-card>
      <v-card-title class="text-h5">¿Esta seguro que desea salir de la página actual?</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="my-4 d-flex justify-center">
          <p class="text-subtitle-1">El área de trabajo actual posee cambios sin guardar.</p>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn class="mb-4 white--text" color="green"
          @click="$emit('accept'), closeDialog('CambiosSinGuardar')">
          Continuar
        </v-btn>
        <v-btn class="mb-4"
          @click="$emit('cancel'), closeDialog('CambiosSinGuardar')">
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "CambiosSinGuardar",
  computed: {
    ...mapState(["Dialogs"]),
  },
  methods: {
    ...mapMutations(["closeDialog"]),
  },
};
</script>