<template>
  <div id="loader-wrapper">
    <div id="loader"></div>
    <div class="loader-section section-left"></div>
    <div class="loader-section section-right"></div>
    <div id="loader-text">
      {{ mensajeActual }}
    </div>
  </div>
</template>

<script>
import ListadoMensajes from "@/utils/helpers/ListadoMensajesCarga";
import { mapState } from "vuex";

export default {
  name: "LoaderComponent",
  data() {
    return {
      mensajes: ListadoMensajes || [],
      mensajeActual: "",
    }
  },
  computed: {
    ...mapState(["Loading"]),
  },
  watch: {
    Loading(to, from) {
      if (to !== from) {
        this.toggleLoader();
      }
    },
  },
  methods: {
    toggleLoader() {
      const b = document.body;
      if (this.Loading) {
        b.classList.remove("loaded");
      } else {
        b.classList.add("loaded");
      }
    },
    generarMensajeRandom() {
      this.mensajeActual = this.mensajes[Math.floor(Math.random()*(this.mensajes.length+1))]
    }
  },
  created() {
    this.generarMensajeRandom();

    setInterval(() => {
      this.generarMensajeRandom()
    }, 10000);
  }
};
</script>

<style lang="scss" scoped>
#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
#loader {
  width: 250px;
  height: 250px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #fff;
  animation: spin 1.7s linear infinite;
  z-index: 11;
  &:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #000;
    animation: spin-reverse 0.6s linear infinite;
  }
  &:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #b3b58c;
    animation: spin 1s linear infinite;
  }
}
#loader-text {
  position: absolute;
  top: 75%;
  max-width: 75vw;
  inline-size: auto;
  text-align: center;
  overflow-wrap: break-word;
  z-index: 12;
  font-size: 1.5rem;
  font-weight: bolder;
  color: white;
  animation: pulse 5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
// Bourbon mixins
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin-reverse {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@keyframes pulse {  
	0% {
    opacity: 0;
	}
	25% {
    opacity: .25;
	}
	50% {
    opacity: .5;
	}
	75% {
    opacity: .75;
	}
	100% {
    opacity: 1;
	}
}
#loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  width: 51%;
  height: 100%;
  background: #b01206;
  z-index: 10;
}
#loader-wrapper .loader-section.section-left {
  left: 0;
}
#loader-wrapper .loader-section.section-right {
  right: 0;
}
/* Loaded styles */
.loaded #loader-text {
  visibility: hidden;
}
.loaded #loader-wrapper .loader-section.section-left {
  transform: translateX(-100%);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.loaded #loader-wrapper .loader-section.section-right {
  transform: translateX(100%);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.loaded #loader {
  opacity: 0;
  transition: all 0.3s ease-out;
}
.loaded #loader-wrapper {
  visibility: hidden;
  transform: translateY(-100%);
  transition: all 0.3s 1s ease-out;
}
</style>
