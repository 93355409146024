export default [
  "Si estás leyendo esto, significa que la página aún no ha cargado.",
  "¡Tómate un descanso y vuelve en unos segundos!",
  "¡No te preocupes! La página se está cargando tan rápido como un caracol corriendo una maratón.",
  "¡Que no cunda el pánico! La página todavía está en camino.",
  "En una galaxia muy lejana, la página se está cargando lentamente.",
  "¡Esperemos que no tardemos tanto como un viaje a Tatooine!",
  "¿Por qué la página tarda tanto en cargar? Tal vez esté navegando por el océano de bits y se ha encontrado con una ballena.",
  "La página se está cargando... ¡sólo un momento! En el ínterin, ¿has probado a aporrear el teclado?",
  "No te preocupes, la página se está cargando lentamente pero con determinación, como una tortuga en una carrera de velocidad.",
  "La página se está cargando! Por favor, no intentes golpear el monitor para hacerla cargar más rápido. No funciona (lo he intentado).",
  "¿La página sigue sin cargar? ¿Qué tal si cantamos juntos una canción de cuna para que se relaje y cargue más rápido?",
  "¿Por qué la página tarda tanto en cargar? Tal vez los pingüinos están bailando la conga en el servidor. ¡A ver si se apuran!",
  "¡Mientras la página carga, dime el manifiesto Saúl!",
  '"La paciencia es la madre de la ciencia" (Refrán popular)',
  "¿Sabías que el 90% de los usuarios abandonan una página web si tarda más de 10 segundos en cargar? ¡No seas uno de ellos!",
  "Si te aburres mucho, puedes intentar contar cuántos píxeles hay en tu pantalla. Te advertimos que son muchos.",
  "Mientras esperas, puedes aprovechar para hacer algunas cosas: revisar tu correo, llamar a tu madre, ordenar tu escritorio, meditar, etc.",
  "Esperando que cargue la página como quien espera que llegue el viernes.",
  "Esperando que cargue la página como quien espera la lluvia en el desierto",
  "No hay mayor prueba de amor que esperar a alguien o algo, por favor espéranos un poco mas.",
  "Esperar es un arte que se cultiva con paciencia y café.",
  "Lo bueno se hace esperar, nosotros no somos la excepción.",
  "Esperar no es perder el tiempo, es ganar experiencia."
]
