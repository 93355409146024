export default {
  /* Información Avatar */
  AvatarInfo(state) {
    const ArrayNombre = state.UserInfo?.name.split(' ');
    const Nombre = ArrayNombre[0] || 'Usuario';
    const Apellido = ArrayNombre[2] || ArrayNombre[1] || 'Usuario';
    const mail = state.UserInfo?.username;
    return ArrayNombre
      ? {
        initials: Nombre.charAt(0) + Apellido.charAt(0),
        shortname: Nombre + ' ' + Apellido,
        mail
      }
      : {
        initials: "JD",
        shortname: "Jhon Doe",
        mail: 'jhon@doe.com'
      }
  },
  MenuItems(state) {
    const items = state.UserPermissions?.routes_with_access;

    return (
      items || [
        {
          title: "Solo Acceso",
          path: "/",
          icon: "mdi-account",
        },
      ]
    );
  },
  ListadoPaisesAsignados(state) {
    const paises = state.UserEssentials?.assigned?.paises;


    return (
      paises || [
        {
          Pais: "Cargando...",
          CodigoPais: null,
        },
      ]
    );
  },
};
