<template>
  <v-navigation-drawer v-if="showSidebar" app clipped permanent>
    <v-list>
      <!-- AVATAR -->
      <v-list-item @click.prevent="validAction('/home')" class="my-5">
        <v-list-item-avatar class="pa-2" color="black" size="large">
          <span class="text-h5" style="color: white;">{{ AvatarInfo.initials }}</span>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ AvatarInfo.shortname }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ AvatarInfo.mail }}
          </v-list-item-subtitle>
        </v-list-item-content>

      </v-list-item>
      <v-divider></v-divider>
      <!-- /AVATAR -->
      <v-list-item v-for="item in MenuItems" :key="item.title" @click.prevent="validAction(item.path)" link>
        <v-list-item-action>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-title> {{ item.title }} </v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>
    </v-list>

    <CambiosSinGuardar @accept="acceptAction" @cancel="capturedPath = null" />
  </v-navigation-drawer>
</template>

<script>
import CambiosSinGuardar from "@/components/dialogs/CambiosSinGuardar.vue";
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  name: "SidebarComponent",
  components: { CambiosSinGuardar },
  data() {
    return {
      capturedPath: null,
    }
  },
  computed: {
    ...mapState({
      showSidebar: "Sidebar",
      VistaEnEdicion: "VistaEnEdicion"
    }),
    ...mapGetters(["AvatarInfo", "MenuItems"]),
  },
  methods: {
    validAction(path) {
      this.capturedPath = path
      if (this.VistaEnEdicion) {
        this.$store.commit(
          "showDialog",
          { who: "CambiosSinGuardar" }
        )
      } else {
        this.acceptAction()
      }
    },
    acceptAction() {
      if (this.VistaEnEdicion) {
        this.$store.commit("toggleVistaEnEdicion")
      }

      this.$router.push({ path: this.capturedPath });
    }
  },
};
</script>
