import api from '../index'

export default {
  permissions,
  unidades_asignadas,
  catalogo_paises,
  usuarios_list,
  plazas_list,
  plazas_metas,
  puestos_list,
  paises_asignados,
  divisiones_asignadas,
  calendario_friedman,
  detalle_metas,
  unidades,
  unidades_all,
  division_plaza,
  paises_plaza,
  unidades_plaza,
  permisos_plaza,
  metas,
  metas_unidad,
  ingresar_metas,
  ingresar_distribucion_metas,
  actualizar_estado_semana_friedman,
  actualizar_distribucion_metas,
  empleados_unidad,
  actualizar_metas,
  cambio_cuenta_mesero,
  upsert_cambio_cuenta_mesero,
  bitacora,
  crear_usuario,
  crear_plaza,
  crear_pais,
  crear_division,
  crear_permisos_metas,
  crear_permisos_distribucion,
  crear_unidades_distribuir,
  get_catalogos_divisiones,
  get_catalogos_paises,
  cerrar_mes_calendario_friedman,
  anios_calendario_friedman_list,
  actualizar_distribucion_metas_T,
  reportes_list_tbl_vw,
  reportes_list,
  reportes_web_list,
  reportes_web_tbl_vw,
  usuarios_AD,
  ventas_sap_micros,
  empleados_unidades,
  plazas_vacantes_traslados_unidad,
  empleados_traslados_pendientes,
  insertar_traslado_sp,
  insertar_traslado_tmp,
  delete_unidades_plaza,
  autoriza_traslado_sp,
  declina_traslado_sp,
  delete_traslado_tmp,
  obtener_traslados_tmp,
  empleados_r1,
  Sincroniza_empleados_r1,
  upsert_metas,
  delete_metas,
  actualizar_distribucion_after_metas,
  get_catalogo_mercados,
  obtener_reporte_cuadre_sap_micros,
  get_metas_mercado,
  delete_metas_mercado,
  ingresar_metas_mercado
}

async function ingresar_metas_mercado(body) {
  const { data } = await api.from('/app-metas/metas-mercado').post(body)

  return data || null
}

async function delete_metas_mercado(Pais, Codigo_Unidad, Mes, Anio, No_Semana, Division) {
  const { data } = await api.from('/app-metas/delete-metas-mercado').del( 
    null, 
    {
      Pais,
      Codigo_Unidad,
      Mes,
      Anio,
      No_Semana,
      Division,
    })

  return data || null
}

async function get_metas_mercado(params) {
  const { data } = await api.from('/app-metas/get-metas-mercado').get(params)

  return data || null
}

async function obtener_reporte_cuadre_sap_micros(params) {
  const { data } = await api.from('/app-metas/rpt-cuadre-sap-micros').get(params)

  return data || null
}

async function actualizar_distribucion_after_metas(Division,Pais,Anio,Mes,Semana) {
  const { data } = await api.from('/app-metas/actualizar-distribucion-after-metas').post(
    null,
    {
      Division,
      Pais,
      Anio,
      Mes,
      Semana
    }
  )

  return data || null
}


async function Sincroniza_empleados_r1() {
  const { data } = await api.from('/app-metas/sincronizacion-vendedores-r1').post()

  return data || null
}

async function empleados_r1() {
  const { data } = await api.from('/app-metas/catalogo-empleados-r1').get({ })

  return data || null
}

async function obtener_traslados_tmp(UnidadDestino) {
  const { data } = await api.from('/app-metas/tmp-traslados').get({ Codigo_Unidad_Destino: UnidadDestino })

  return data || null
}

async function delete_unidades_plaza(IdPlazaEvolution) {
  const response = await api.from('/app-metas/delete-unidades-plaza').del(null, { IdPlazaEvolution })

  return response || null
}

async function autoriza_traslado_sp(body) {
  // :body: = { codigo_sujeto, fecha_vigencia }
  const response = await api.from('/app-metas/autoriza-sp-interfaz-traslados').post(body)

  return response || null
}

async function declina_traslado_sp(body) {
  // :body: = { codigo_sujeto, fecha_vigencia }
  const response = await api.from('/app-metas/declina-sp-interfaz-traslados').post(body)

  return response || null
}

async function delete_traslado_tmp(body) {
  // :body: = { Codigo_Unidad_Origen, Codigo_Empleado, Codigo_Unidad_Destino, Fecha_Traslado }
  const response = await api.from('/app-metas/delete-decline-traslados').del(null, body)

  return response || null
}

async function insertar_traslado_tmp(body) {
  // :body: = { Codigo_Unidad_Origen, Codigo_Empleado, Pais, Codigo_Unidad_Destino, Horas_Trabajadas, Fecha_Traslado, Estado }
  const { data } = await api.from('/app-metas/upsert-tmp-traslados').post(body)

  return data || null
}

async function insertar_traslado_sp(body) {
  // :body: = { codigo_solicitante, codigo_sujeto, fecha_solicitud, fecha_vigencia, plaza_anterior, plaza_nueva }
  const { data } = await api.from('/app-metas/inserta-plaza-sp-traslados').post(body)

  return data || null
}

async function empleados_traslados_pendientes(UnidadDestino, AMP_CODCIA = 1) {
  const { data } = await api.from('/app-metas/movimientos-pendientes-traslados').get({ UnidadDestino, AMP_CODCIA })

  return data || null
}

async function plazas_vacantes_traslados_unidad(Codigo_Unidad, Estado = "Vacante") {
  const { data } = await api.from('/app-metas/plazas-unidad-traslados').get({ Codigo_Unidad, Estado })

  return data || null
}

async function empleados_unidades(relacion_sap) {

  const { data } = await api.from('/app-metas/empleados-unidad-traslados').get({ relacion_sap })

  return data || null
}

async function ventas_sap_micros(pais, correo_notificacion) {
  let Parametros = { Parametros: [] };
  Parametros.Parametros.push({
    Pais: pais,
    CorreoNotificacion: correo_notificacion,
  });
  const response = await api.from('/app-metas/ventas-sap-micros').post(Parametros)
  return response
}

async function usuarios_AD() {
  const { data } = await api.doget_AD('/api/aad_users_list_get_http')

  return data || null
}

async function reportes_list() {
  const { data} = await api.from('/app-metas/get_lista_reportes').get()

  return data || null
}

async function reportes_list_tbl_vw(params) {
  const { data } = await api.from('/app-metas/call_tbl_reporte').get(params)

  return data || null
}

async function reportes_web_list() {
  const { data } = await api.from('/app-metas/get_lista_reportes_web').get()

  return data || null
}

async function reportes_web_tbl_vw(body) {
  /* 
  {
    "how_many": "TOP 5",
    "objeto": "[VW_Cuentas]",
    "clause": "CONVERT(date,FechaInicio) BETWEEN '2023-01-30' AND Codigo_Unidad = 'C00' AND Cuenta = '3001' AND Pais = 'GT'"
  }
  */

  const { data } = await api.from('/app-metas/call_tbl_reportes_web').post(body)

  return data || null
}

async function cerrar_mes_calendario_friedman(anio, no_mes) {
  const { data } = await api.from('/app-metas/cerrar-mes-calendario-friedman').post({ anio, no_mes })

  return data || null
}

/* Reemplazado temporalmente por: helpers/FormatSemanaFriedman */
async function anios_calendario_friedman_list() {
  const { data } = await api.from('/app-metas/anios-calendario-friedman-list').get()

  return data || null
}

async function permissions(usuario_app) {
  const { data } = await api.from('/app-metas/permissions').get({ usuario_app })

  return data || null
}

async function unidades_asignadas(usuario_app) {
  const { data } = await api.from('/app-metas/unidades_asignadas').get({ usuario_app })

  return data || null
}

async function catalogo_paises(usuario_app) {
  const { data } = await api.from('/app-metas/paises_asignados').get({ usuario_app })

  return data || null
}

async function usuarios_list() {
  const { data } = await api.from('/app-metas/usuarios_list').get()

  return data || null
}

async function plazas_list() {
  const { data } = await api.from('/app-metas/plazas_list').get()

  return data || null
}

async function plazas_metas() {
  const { data } = await api.from('/app-metas/plazas_metas').get()

  return data || null
}

async function puestos_list() {
  const { data } = await api.from('/app-metas/puestos_list').get()

  return data || null
}

async function paises_asignados(usuario_app) {
  const { data } = await api.from('/app-metas/paises_asignados').get({ usuario_app })

  return data || null
}

async function divisiones_asignadas(usuario_app) {
  const { data } = await api.from('/app-metas/divisiones_asignadas').get({ usuario_app })

  const divisiones = data.map(({ Division }) => Division)

  return divisiones.length > 0 ? divisiones : null
}


async function calendario_friedman(anio, no_mes) {
  const { data } = await api.from('/app-metas/calendario-friedman').get({
    anio,
    no_mes
  })

  return data || null
}

async function get_catalogo_mercados({codigo_unidad})
{
const { data } = await api.from('/app-metas/get-catalogo-mercados').get({codigo_unidad
})
 return data || null
}

async function detalle_metas({ fecha_inicio, fecha_fin, codigo_unidad, pais }) {
  const { data } = await api.from('/app-metas/distribucion-metas').get({
    fecha_inicio,
    fecha_fin,
    codigo_unidad,
    pais
  })

  return data || null
}

async function unidades(pais) {
  const { data } = await api.from('/app-metas/unidades').get({ pais })

  return data || null
}

async function unidades_all() {
  const { data } = await api.from('/app-metas/unidades').get()

  return data || null
}

async function division_plaza(IdPlazaEvolution) {
  const { data } = await api.from('/app-metas/divisiones_plaza').get({ IdPlazaEvolution })

  return data || null
}

async function paises_plaza(IdPlazaEvolution) {
  const { data } = await api.from('/app-metas/paises_plaza').get({ IdPlazaEvolution })

  return data || null
}

async function unidades_plaza(IdPlazaEvolution) {
  const { data } = await api.from('/app-metas/unidades_plaza').get({ IdPlazaEvolution })

  return data || null
}

async function permisos_plaza(IdPlazaEvolution) {
  const { data } = await api.from('/app-metas/permisos_plaza').get({ IdPlazaEvolution })

  return data || null
}

async function metas(fecha_inicio, fecha_fin, pais) {
  const { data } = await api.from('/app-metas/metas').get({
    fecha_inicio,
    fecha_fin,
    pais
  })

  return data || null
}

async function metas_unidad({ fecha_inicio, fecha_fin, pais, codigo_unidad }) {
  const { data } = await api.from('/app-metas/metas').get({
    fecha_inicio,
    fecha_fin,
    pais,
    codigo_unidad
  })

  return data || null
}

async function delete_metas(Pais, Mes, Anio, No_Semana, Division) {

  const { data } = await api.from('/app-metas/delete-metas').del( 
    null, 
    {
      Pais,
      Mes,
      Anio,
      No_Semana,
      Division,
    })

  return data || null
}

async function ingresar_metas(body) {
  const { data } = await api.from('/app-metas/metas').post(body)

  return data || null
}

async function upsert_metas(body) {
  const { data } = await api.from('/app-metas/upsert-metas').post(body)

  return data || null
}

async function ingresar_distribucion_metas(body) {
  const { data } = await api.from('/app-metas/distribucion-metas').post(body)

  return data || null
}

async function actualizar_estado_semana_friedman({ anio, no_mes, no_semana, body }) {
  const { data } = await api.from('/app-metas/calendario-friedman').put(
    body,
    {
      anio,
      no_mes,
      no_semana
    }
  )

  return data || null
}

async function actualizar_distribucion_metas({ fecha_inicio, codigo_unidad, pais, codigo_empleado, body }) {
  const { data } = await api.from('/app-metas/distribucion-metas').put(
    body,
    {
      fecha_inicio,
      codigo_unidad,
      pais,
      codigo_empleado
    }
  )

  return data || null
}

function actualizar_distribucion_metas_T({ fecha_inicio, codigo_unidad, pais, codigo_empleado, body }) {
  const result = api.from('/app-metas/distribucion-metas').put(
    body,
    {
      fecha_inicio,
      codigo_unidad,
      pais,
      codigo_empleado
    }
  )
}

async function empleados_unidad(codigo_unidad, pais = null) {
  const { data } = await api.from('/app-metas/empleados').get(pais !== null ? { codigo_unidad, pais } : { codigo_unidad })

  return data || null
}

async function actualizar_metas({ fecha_inicio, codigo_unidad, pais, body }) {
  const { data } = await api.from('/app-metas/metas').put(
    body,
    {
      fecha_inicio,
      codigo_unidad,
      pais
    }
  )

  return data || null
}

async function cambio_cuenta_mesero(anio, mes = 'All') {
  const { data } = await api.from('/app-metas/cambio_cuenta_mesero_list').get((mes == 'All') ? { anio } : { anio, mes })

  return data || null
}

async function upsert_cambio_cuenta_mesero(objData) {
  const { data } = await api.from('/app-metas/upsert_cambio_cuenta_mesero').post(objData)

  return data || null
}

async function bitacora(objData) {
  const { data } = await api.from('/app-metas/bitacoras').post(objData)

  return data || null
}

async function crear_usuario(objData) {
  const { data } = await api.from('/app-metas/upsert_usuario').post(objData)

  return data || null
}

async function crear_plaza(objData) {
  const { data } = await api.from('/app-metas/upsert_plaza').post(objData)

  return data || null
}

async function crear_pais(objData) {
  const { data } = await api.from('/app-metas/upsert_paises').post(objData)

  return data || null
}

async function crear_division(objData) {
  const { data } = await api.from('/app-metas/upsert_divisiones').post(objData)

  return data || null
}

async function crear_permisos_metas(objData) {
  const { data } = await api.from('/app-metas/upsert_metas').post(objData)

  return data || null
}

async function crear_permisos_distribucion(objData) {
  const { data } = await api.from('/app-metas/upsert_distribucion').post(objData)

  return data || null
}

async function crear_unidades_distribuir(objData) {
  const { data } = await api.from('/app-metas/upsert_unidades').post(objData)

  return data || null
}

async function get_catalogos_divisiones() {
  const { data } = await api.from('/app-metas/catalogo_divisiones').get()

  return data || null
}

async function get_catalogos_paises() {
  const { data } = await api.from('/app-metas/catalogo_paises').get()

  return data || null
}
